import React, { useState } from 'react';
import { motion } from 'framer-motion';

const HomeFAQ = () => {
  const [openIndex, setOpenIndex] = useState(0);

  const faqs = [
    {
      question: "What exactly does Notifyr do?",
      answer: "Notifyr instantly notifies you when your keywords (brand, product, or any topic) are mentioned on Reddit. You'll receive real-time alerts through Discord or email, helping you stay on top of conversations that matter to you."
    },
    {
      question: "How much does it cost?",
      answer: "We offer a free plan that lets you monitor 3 subreddits with basic keywords. Premium plans start at $6.00/month, allowing you to monitor multiple subreddits with advanced features like regex matching, sentiment analysis, and custom notification settings."
    },
    {
      question: "How easy is it to set up?",
      answer: "Setup takes less than 2 minutes. Simply sign up, choose your keywords, select your subreddits, and connect your Discord server or email. No coding required - our intuitive dashboard makes it easy to manage everything."
    },
    {
      question: "Will I get spam notifications?",
      answer: "No. You have full control over your notification settings. Set up precise keywords, use exclusion filters, and customize notification frequency. Discord alerts are instant, while email notifications can be batched hourly or daily."
    },
    {
      question: "Can I try before I buy?",
      answer: "Yes! Start with our free plan to monitor one subreddit. You'll get access to basic features and can upgrade anytime. We also offer a 7-day money-back guarantee on all premium plans."
    },
    {
      question: "What makes Notifyr better than manual monitoring?",
      answer: "Notifyr saves you hours of manual checking. We monitor Reddit 24/7 with 99.9% uptime, process millions of posts in real-time, and deliver instant notifications. Plus, our advanced filters help you catch only the most relevant mentions."
    }
  ];

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? -1 : index);
  };

  return (
    <section className="relative py-16 lg:py-24 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="text-center mb-12 lg:mb-16">
          <h2 className="text-4xl lg:text-6xl font-extrabold text-slate-700 mb-4 tracking-tight">
            Frequently Asked Questions
          </h2>
          <p className="text-lg lg:text-xl text-gray-600 max-w-2xl mx-auto">
            Got questions? We've got answers.
          </p>
        </div>

        <div className="max-w-3xl mx-auto">
          <div className="grid gap-3">
            {faqs.map((faq, index) => (
              <motion.div
                key={index}
                className={`overflow-hidden rounded-lg border transition-all duration-200
                  ${openIndex === index 
                    ? 'bg-white border-blue-200 ring-1 ring-blue-200 shadow-sm'
                    : 'bg-white border-gray-200 hover:border-blue-200'
                  }`}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.2, delay: index * 0.05 }}
              >
                <button
                  className="w-full text-left px-5 py-4 flex justify-between items-center focus:outline-none group"
                  onClick={() => toggleFAQ(index)}
                >
                  <span className={`font-medium transition-colors duration-200
                    ${openIndex === index ? 'text-blue-600' : 'text-gray-900 group-hover:text-blue-600'}`}
                  >
                    {faq.question}
                  </span>
                  <motion.div 
                    className={`ml-4 flex-shrink-0 p-1 rounded-md transition-colors duration-200
                      ${openIndex === index 
                        ? 'text-blue-600'
                        : 'text-gray-400 group-hover:text-blue-600'
                      }`}
                    animate={{ rotate: openIndex === index ? 180 : 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2.5} d="M19 9l-7 7-7-7" />
                    </svg>
                  </motion.div>
                </button>
                <motion.div
                  initial={false}
                  animate={{ 
                    height: openIndex === index ? "auto" : 0,
                    opacity: openIndex === index ? 1 : 0
                  }}
                  transition={{ duration: 0.2 }}
                  className="overflow-hidden"
                >
                  <div className="px-5 pt-4 pb-4 text-gray-600 border-t border-gray-100">
                    {faq.answer}
                  </div>
                </motion.div>
              </motion.div>
            ))}
          </div>

          <motion.div 
            className="text-center mt-12"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.5 }}
          >
            <a
              href="/pricing#faq"
              className="inline-flex items-center px-5 py-2.5 rounded-lg bg-white border border-gray-200 text-blue-600 hover:text-blue-700 hover:border-blue-200 font-medium transition-all duration-200"
            >
              View all FAQs
              <svg
                className="ml-2 w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 7l5 5m0 0l-5 5m5-5H6"
                />
              </svg>
            </a>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default HomeFAQ;