import React from 'react';
import { Hash } from 'lucide-react';
import LoadingSpinner from '../../components/LoadingSpinner';

const TopKeywords = ({ topKeywords, selectedKeyword, setSelectedKeyword }) => {
  const getRankColor = (index) => {
    switch(index) {
      case 0: return 'text-yellow-500';
      case 1: return 'text-gray-500';
      case 2: return 'text-orange-500';
      default: return 'text-blue-600';
    }
  };

  return (
    <div className="lg:w-1/4 bg-white rounded-lg shadow-sm p-6 border border-gray-200 hover:border-blue-300 transition-all duration-300 flex flex-col h-[50vh] lg:h-full max-h-[calc(100vh-12rem)]">
      <h2 className="text-xl font-semibold mb-4 text-gray-800 flex items-center">
        <Hash className="w-5 h-5 text-blue-500 mr-2" />
        Top Keywords
      </h2>
      <div className="overflow-y-auto flex-grow">
        {topKeywords && topKeywords.length > 0 ? (
          topKeywords.map((kw, index) => (
            <div
              key={kw.id}
              onClick={() => setSelectedKeyword(kw)}
              className={`p-3 rounded-lg cursor-pointer mb-2 border transition-all duration-300 ${
                selectedKeyword && selectedKeyword.id === kw.id
                  ? 'bg-blue-50 border-blue-300 shadow-sm'
                  : 'hover:bg-gray-50 border-gray-200'
              }`}
            >
              <div className="flex justify-between items-center">
                <p className="font-semibold text-gray-700">{kw.keyword}</p>
                <span className={`text-sm font-bold ${getRankColor(index)}`}>
                  {index < 3 ? `#${index + 1}` : ''}
                </span>
              </div>
              <p className="text-sm text-gray-500">r/{kw.subreddit}</p>
              <p className="text-sm text-blue-600 mt-1">24h matches: {kw.recent_matches}</p>
            </div>
          ))
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </div>
  );
};

export default TopKeywords;