import { useState, useEffect, useRef, useCallback } from 'react';
import { useAuth } from '../AuthContext';

const useAuthenticatedApi = () => {
    const { user, getAccessToken, refreshToken } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const initialFetchMade = useRef({});
    const isFetchingRef = useRef(false);
    const lastUserIdRef = useRef(null);

    useEffect(() => {
        // Only reset fetch state when user ID actually changes and we had a previous user
        // This prevents resetting on initial auth and duplicate session events
        if (!user?.id) {
            // No user, clear everything
            initialFetchMade.current = {};
            lastUserIdRef.current = null;
            return;
        }

        const userIdChanged = user.id !== lastUserIdRef.current;
        if (userIdChanged && lastUserIdRef.current !== null) {
            // Only reset on actual user change, not initial auth
            console.log('User ID changed from', lastUserIdRef.current, 'to', user.id);
            initialFetchMade.current = {};
        }
        lastUserIdRef.current = user.id;
    }, [user?.id]);

    const authFetch = useCallback(async (url, options = {}) => {
        let token = getAccessToken();

        if (!token) {
            token = await refreshToken();
            if (!token) {
                throw new Error('Unable to refresh token');
            }
        }

        const authOptions = {
            ...options,
            headers: {
                ...options.headers,
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        };

        let response = await fetch(url, authOptions);
        console.log('Raw fetch response:', response);

        if (response.status === 401) {
            token = await refreshToken();
            if (token) {
                authOptions.headers['Authorization'] = `Bearer ${token}`;
                response = await fetch(url, authOptions);
            } else {
                throw new Error('Session expired. Please log in again.');
            }
        }

        const data = await response.json();
        console.log('Parsed response data:', data);

        if (Array.isArray(data) && data.length === 2 && data[1] >= 400) {
            throw new Error(data[0].message || 'API request failed');
        }

        if (!response.ok || (data.status === 'error')) {
            throw new Error(data.message || 'API request failed');
        }

        return data;
    }, [getAccessToken, refreshToken]);

    const executeRequest = useCallback(async (fetchFunction, dependencies = [], isInitialFetch = false) => {
        if (!user?.id) {
            console.log('No user ID available, skipping request');
            return null;
        }

        const requestKey = JSON.stringify({ 
            fn: fetchFunction.toString(),
            deps: dependencies,
            userId: user.id // Include userId in the key to differentiate between users
        });

        // Skip if this is an initial fetch we've already done for this user
        if (isInitialFetch && initialFetchMade.current[requestKey]) {
            console.log('Skipping duplicate initial fetch for user:', user.id);
            return null;
        }

        console.log('Executing request:', { 
            userId: user.id, 
            isInitialFetch,
            isFetching: isFetchingRef.current 
        });

        setIsLoading(true);
        setError(null);

        try {
            const result = await fetchFunction(user.id);
            if (isInitialFetch) {
                initialFetchMade.current[requestKey] = true;
            }
            return result;
        } catch (err) {
            setError(err);
            throw err;
        } finally {
            setIsLoading(false);
        }
    }, [user?.id]);

    const useApiRequest = (fetchFunction, dependencies = []) => {
        const [data, setData] = useState(null);
        const mountedRef = useRef(true);
        const fetchInProgressRef = useRef(false);
        const dependenciesRef = useRef(dependencies);
        const requestId = useRef(JSON.stringify({ fn: fetchFunction.toString(), deps: dependencies }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const memoizedFetchFunction = useCallback(fetchFunction, []);

        useEffect(() => {
            const currentRequestId = requestId.current;
            return () => {
                mountedRef.current = false;
                // Safe to use currentRequestId here since it's captured in closure
                delete initialFetchMade.current[currentRequestId];
            };
        }, []);

        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect(() => {
            if (!mountedRef.current || !user?.id || fetchInProgressRef.current) {
                return;
            }

            const currentRequestId = requestId.current;
            if (initialFetchMade.current[currentRequestId]) {
                console.log('Skipping fetch - already made initial request for current user');
                return;
            }

            const fetchData = async () => {
                if (fetchInProgressRef.current) return;
                
                fetchInProgressRef.current = true;
                try {
                    console.log('Making initial request for user:', user.id);
                    const result = await executeRequest(memoizedFetchFunction, dependenciesRef.current, true);
                    if (mountedRef.current && result) {
                        setData(result);
                        initialFetchMade.current[currentRequestId] = true;
                    }
                } finally {
                    fetchInProgressRef.current = false;
                }
            };

            fetchData();
        }, [memoizedFetchFunction]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
        const refetch = useCallback(async () => {
            if (fetchInProgressRef.current) {
                return null;
            }

            fetchInProgressRef.current = true;
            try {
                const result = await executeRequest(memoizedFetchFunction, dependenciesRef.current, false);
                if (mountedRef.current && result) {
                    setData(result);
                }
                return result;
            } finally {
                fetchInProgressRef.current = false;
            }
        }, [memoizedFetchFunction]);

        return { data, isLoading, error, refetch };
    };

    return { authFetch, useApiRequest, executeRequest };
};

export default useAuthenticatedApi;
