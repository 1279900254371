import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from './AuthContext';

const Navbar = () => {
  const location = useLocation();
  const { user, logout } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Error during logout:', error.message);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const backdropVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const menuVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        damping: 25,
        stiffness: 200,
      },
    },
  };

  return (
    <>
      <div className="h-24"></div>
      <div className="fixed top-0 left-0 right-0 z-50 py-4 px-4 sm:px-6 lg:px-8">
        <nav className="container mx-auto px-4 bg-white bg-opacity-70 backdrop-filter backdrop-blur-sm shadow-lg rounded-lg">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center h-16">
              <div className="flex-shrink-0 flex items-center">
                <Link to="/" className="flex items-center">
                  <img className="h-10 w-auto" src="/img/logo.svg" alt="Logo" />
                </Link>
              </div>
              <div className="hidden lg:flex items-center space-x-8">
                <NavLink to="/keywords" currentPath={location.pathname}>Keywords</NavLink>
                <NavLink to="/alerts" currentPath={location.pathname}>Alerts</NavLink>
                <NavLink to="/stats" currentPath={location.pathname}>Stats</NavLink>
                {/* <NavLink to="/settings" currentPath={location.pathname}>Settings</NavLink> */}
              </div>
              <div className="hidden lg:flex items-center space-x-4">
                {user ? (
                  <>
                    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                      <Link to="/account" className="px-4 py-2 rounded-md text-lg font-bold bg-blue-600 text-white hover:bg-blue-700 transition duration-150 ease-in-out">
                        Account
                      </Link>
                    </motion.div>
                    <motion.button
                      onClick={handleLogout}
                      className="px-4 py-2 rounded-md text-lg font-bold text-gray-700 hover:text-blue-600 transition duration-150 ease-in-out"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      Logout
                    </motion.button>
                  </>
                ) : (
                  <>
                    <motion.a
                      href="/login"
                      className="px-4 py-2 rounded-md text-lg font-bold text-blue-600 hover:bg-blue-50 transition duration-150 ease-in-out"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      Log In
                    </motion.a>
                    <motion.a
                      href="/register"
                      className="px-4 py-2 rounded-md text-lg font-bold bg-blue-600 text-white hover:bg-blue-700 transition duration-150 ease-in-out"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      Sign Up
                    </motion.a>
                  </>
                )}
              </div>
              <div className="lg:hidden flex items-center justify-center">
                <button onClick={toggleMenu} className="text-gray-700 hover:text-blue-600">
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <AnimatePresence>
        {isMenuOpen && (
          <>
            <motion.div
              className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm z-40"
              variants={backdropVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              onClick={toggleMenu}
            />
            <div className="fixed top-24 left-0 right-0 px-4 sm:px-6 lg:px-8 z-50">
              <motion.div
                className="container mx-auto bg-white bg-opacity-70 backdrop-filter backdrop-blur-sm shadow-lg rounded-lg overflow-hidden"
                variants={menuVariants}
                initial="hidden"
                animate="visible"
                exit="hidden"
              >
                <div className="px-4 pt-2 pb-3 space-y-1">
                  <NavLink to="/keywords" currentPath={location.pathname} onClick={toggleMenu}>Keywords</NavLink>
                  <NavLink to="/alerts" currentPath={location.pathname} onClick={toggleMenu}>Alerts</NavLink>
                  <NavLink to="/stats" currentPath={location.pathname} onClick={toggleMenu}>Stats</NavLink>
                  {/* <NavLink to="/settings" currentPath={location.pathname} onClick={toggleMenu}>Settings</NavLink> */}
                  {user ? (
                    <>
                      <Link to="/account" className="block px-3 py-2 rounded-md text-lg font-bold text-gray-700 hover:text-blue-600 hover:bg-gray-200" onClick={toggleMenu}>Account</Link>
                      <button onClick={() => { handleLogout(); toggleMenu(); }} className="block w-full text-left px-3 py-2 rounded-md text-base font-bold text-gray-700 hover:text-blue-600 hover:bg-gray-200">Logout</button>
                    </>
                  ) : (
                    <>
                      <Link to="/login" className="block px-3 py-2 rounded-md text-lg font-bold text-gray-700 hover:text-blue-600 hover:bg-gray-200" onClick={toggleMenu}>Log In</Link>
                      <Link to="/register" className="block px-3 py-2 rounded-md text-lg font-bold text-gray-700 hover:text-blue-600 hover:bg-gray-200" onClick={toggleMenu}>Sign Up</Link>
                    </>
                  )}
                </div>
              </motion.div>
            </div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

const NavLink = ({ to, currentPath, children, onClick }) => {
  const isActive = currentPath === to;
  return (
    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
      <Link
        to={to}
        className={`block px-3 py-2 rounded-md text-lg font-bold ${isActive ? 'text-blue-500' : 'text-gray-700 hover:text-blue-600 hover:bg-gray-200'}`}
        onClick={onClick}
      >
        {children}
      </Link>
    </motion.div>
  );
};

export default Navbar;
