import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { TrendingUp, ExternalLink } from 'lucide-react';
import LoadingSpinner from '../../components/LoadingSpinner';

const KeywordPerformance = ({ selectedKeyword, timeRange, chartData, handleTimeRangeChange }) => {
  const isWeekView = timeRange === '1w';

  const formatTooltip = (value, name, props) => {
    if (name === 'matches') {
      const date = new Date(props.payload.fullDate);
      return [`${value} matches`, date.toLocaleString()];
    }
    return [value, name];
  };

  const getDataTimeRange = () => {
    if (chartData.length < 2) return '12h'; // default or not enough data
    
    const firstDate = new Date(chartData[0].fullDate);
    const lastDate = new Date(chartData[chartData.length - 1].fullDate);
    const diffHours = (lastDate - firstDate) / (1000 * 60 * 60);
    
    if (diffHours > 24) return '1w';
    if (diffHours > 12) return '1d';
    return '12h';
  };

  const formatXAxis = (tickItem) => {
    const date = new Date(tickItem);
    const currentRange = getDataTimeRange();
    
    if (currentRange === '1w') {
      return new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(date);
    }
    
    return new Intl.DateTimeFormat('en-US', { 
      hour: 'numeric',
      hour12: true,
    }).format(date);
  };

  const renderKeywordDisplay = () => {
    const subredditMatch = selectedKeyword.keyword.match(/^r\/(\w+)/i);
    if (subredditMatch) {
      return (
        <div className="flex items-center gap-1.5">
          <span className="text-gray-600 font-medium">Monitoring</span>
          <a
            href={`https://reddit.com/r/${subredditMatch[1]}`}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center gap-1 px-2 py-0.5 bg-blue-50 text-blue-600 hover:text-blue-700 rounded-md font-semibold group transition-all duration-200 hover:bg-blue-100"
          >
            r/{subredditMatch[1]}
            <ExternalLink className="w-3.5 h-3.5 opacity-0 group-hover:opacity-100 transition-opacity duration-200" />
          </a>
        </div>
      );
    }
    return (
      <div className="flex items-center gap-1.5">
        <span className="text-gray-600 font-medium">Keyword</span>
        <span className="px-2 py-0.5 bg-blue-50 text-blue-600 rounded-md font-semibold">
          {selectedKeyword.keyword}
        </span>
      </div>
    );
  };

  const hasNoData = chartData && chartData.length > 0 && 
    chartData.every(data => data.matches === 0);

  return (
    <div className="flex-grow bg-white rounded-lg shadow-md p-4 border border-gray-200 hover:shadow-lg transition-all duration-300 flex flex-col">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 gap-3">
        <div className="flex items-center mb-2 sm:mb-0 min-w-0 max-w-full">
          <TrendingUp className="w-5 h-5 text-blue-500 mr-2.5 flex-shrink-0" />
          <h2 className="text-lg truncate max-w-full">
            {renderKeywordDisplay()}
          </h2>
        </div>
        <div className="flex space-x-2 flex-shrink-0">
          {['12h', '1d', '1w'].map((range) => (
            <button
              key={range}
              onClick={() => handleTimeRangeChange(range)}
              className={`px-3 py-1 rounded-md text-xs font-medium transition-all duration-300 ${
                timeRange === range
                  ? 'bg-blue-500 text-white shadow-md'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              }`}
            >
              {range}
            </button>
          ))}
        </div>
      </div>
      <div className="flex-grow w-full" style={{ height: '300px' }}>
        {!chartData ? (
          <div className="flex items-center justify-center h-full">
            <LoadingSpinner size="medium" />
          </div>
        ) : hasNoData ? (
          <div className="flex flex-col items-center justify-center h-full text-gray-500 p-4 text-center">
            <span className="text-base sm:text-lg font-medium mb-1 sm:mb-2">No matches found</span>
            <span className="text-xs sm:text-sm">
              No data available for the {timeRange} time period
            </span>
          </div>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={chartData} margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
              <XAxis 
                dataKey="fullDate" 
                stroke="#4B5563" 
                tickFormatter={formatXAxis}
                interval={isWeekView ? 0 : 'preserveStartEnd'}
                tick={{ 
                  fontSize: 11, 
                  fontWeight: isWeekView ? '500' : '400' 
                }}
                angle={isWeekView ? 0 : -45}
                textAnchor={isWeekView ? 'middle' : 'end'}
                height={50}
              />
              <YAxis 
                stroke="#4B5563"
                tick={{ fontSize: 10 }}
                width={40}
                tickCount={5}
                allowDecimals={false}
              />
              <Tooltip 
                contentStyle={{ 
                  backgroundColor: 'white', 
                  border: '1px solid #e2e8f0',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                }}
                formatter={formatTooltip}
                animationDuration={300}
              />
              <Bar 
                dataKey="matches" 
                fill="#3B82F6" 
                radius={[4, 4, 0, 0]}
                isAnimationActive={true}
                animationDuration={1000}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default KeywordPerformance;
