import React from 'react';
import { useParams } from 'react-router-dom';
import HowToGetInstantAlerts from './posts/HowToGetInstantAlerts';
import MasteringKeywordAlerts from './posts/MasteringKeywordAlerts';

const blogPosts = {
  'how-to-get-instant-alerts': HowToGetInstantAlerts,
  'mastering-keyword-alerts': MasteringKeywordAlerts,
  // Add more blog posts here as needed
};

const BlogPostPage = () => {
  const { slug } = useParams();
  const PostContent = blogPosts[slug];

  if (!PostContent) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="bg-white p-8 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Post not found</h2>
        </div>
      </div>
    );
  }

  return <PostContent />;
};

export default BlogPostPage;
