import React, { useState, useCallback, useMemo, useEffect } from 'react';
import useAuthenticatedApi from '../../../hooks/useAuthenticatedApi';
import AlertCard from './AlertCard';
import { ChevronDown, Filter, LayoutGrid, List as ListIcon } from 'lucide-react';
import PageLayout from '../../../components/PageLayout';
import AlertList from './AlertList';
import { AnimatePresence, motion } from 'framer-motion';

const ShimmerCard = React.memo(() => {
  return (
    <div className="w-full h-64 bg-white shadow-xl rounded-lg overflow-hidden border border-gray-200 mb-6 animate-pulse">
      <div className="h-20 bg-gray-300"></div>
      <div className="p-4">
        <div className="h-4 bg-gray-300 rounded w-3/4 mb-2"></div>
        <div className="h-4 bg-gray-300 rounded w-1/2 mb-2"></div>
        <div className="h-4 bg-gray-300 rounded w-2/3"></div>
      </div>
    </div>
  );
});

const AppPage = React.memo(() => {
  const { authFetch, executeRequest } = useAuthenticatedApi();
  const [page, setPage] = useState(1);
  const [allAlerts, setAllAlerts] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState('All');
  const [sortOrder, setSortOrder] = useState('newest');
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [viewMode, setViewMode] = useState(() => 
    localStorage.getItem('alertViewMode') || 'grid'
  );

  const fetchAlerts = useCallback(async (userId, pageNum) => {
    console.log('Fetching alerts for page:', pageNum);
    const response = await authFetch(`/api/alerts?user_id=${userId}&page=${pageNum}`);
    console.log('API response:', response);
    return response;
  }, [authFetch]);

  const loadAlerts = useCallback(async (pageNum, isInitialFetch = false) => {
    if (!isInitialFetch) setIsLoading(true);
    try {
      const result = await executeRequest((userId) => fetchAlerts(userId, pageNum), [pageNum], isInitialFetch);
      if (result) {
        if (isInitialFetch || pageNum === 1) {
          setAllAlerts(result.alerts || []);
        } else {
          setAllAlerts(prev => [...prev, ...(result.alerts || [])]);
        }
        setTotalPages(result.total_pages || 1);
      }
    } catch (err) {
      console.error('Error loading alerts:', err);
    } finally {
      setIsLoading(false);
    }
  }, [executeRequest, fetchAlerts]);

  useEffect(() => {
    loadAlerts(1, true);
  }, [loadAlerts]);

  useEffect(() => {
    localStorage.setItem('alertViewMode', viewMode);
  }, [viewMode]);

  const loadMoreAlerts = useCallback(() => {
    console.log('Load More clicked. Current page:', page);
    if (page < totalPages && !isLoading) {
      const nextPage = page + 1;
      setPage(nextPage);
      console.log('Incrementing page to:', nextPage);
      loadAlerts(nextPage);
    }
  }, [page, totalPages, isLoading, loadAlerts]);

  const keywordSet = useMemo(() => {
    if (!allAlerts || allAlerts.length === 0) return new Set(['All']);
    const keywords = allAlerts.flatMap(alert => 
      alert.keywords.map(keyword => keyword.keyword_name)
    );
    return new Set(['All', ...keywords]);
  }, [allAlerts]);

  const filteredAndSortedAlerts = useMemo(() => {
    let filtered = [...allAlerts];
    if (selectedKeyword !== 'All') {
      filtered = filtered.filter(alert => 
        alert.keywords.some(keyword => keyword.keyword_name === selectedKeyword)
      );
    }
    return filtered.sort((a, b) => {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      return sortOrder === 'newest' ? dateB - dateA : dateA - dateB;
    });
  }, [allAlerts, selectedKeyword, sortOrder]);

  const renderContent = () => {
    const contentProps = {
      alerts: filteredAndSortedAlerts,
      onLoadMore: loadMoreAlerts,
      hasMore: page < totalPages,
      isLoading
    };

    return (
      <AnimatePresence mode="wait" initial={false}>
        <motion.div
          key={viewMode}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ 
            duration: 0.15,
            ease: "easeOut"
          }}
          className="w-full"
        >
          {viewMode === 'list' ? (
            <AlertList {...contentProps} />
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {isLoading && filteredAndSortedAlerts.length === 0 ? (
                [...Array(6)].map((_, index) => (
                  <ShimmerCard key={`shimmer-${index}`} />
                ))
              ) : (
                contentProps.alerts.map(alert => (
                  <AlertCard key={alert.alert_id || alert.id} alert={alert} />
                ))
              )}
            </div>
          )}
        </motion.div>
      </AnimatePresence>
    );
  };

  const selectClass = `appearance-none bg-transparent border-none w-full py-2 pl-3 pr-8 cursor-pointer focus:outline-none text-sm font-semibold`;
  const selectWrapperClass = `relative inline-block bg-white text-gray-800 border border-gray-200 rounded-md shadow-sm hover:shadow-md transition-shadow duration-300 w-full mb-2 sm:mb-0 sm:w-auto`;

  return (
    <PageLayout 
      title="Alerts" 
      description="View and manage your Reddit alerts with Notifyr."
      className="max-w-7xl"
      breadcrumbs={[
        { label: 'Dashboard', href: '/' },
        { label: 'Alerts' }
      ]}
    >
      <div className="bg-white shadow-xl rounded-lg p-4 border border-gray-200 mb-6">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
          <button 
            className="mb-4 sm:hidden flex items-center text-gray-600 hover:text-gray-800"
            onClick={() => setIsFilterOpen(!isFilterOpen)}
          >
            <Filter size={20} className="mr-2" />
            {isFilterOpen ? 'Hide Filters' : 'Show Filters'}
          </button>
          
          <div className={`flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-4 w-full sm:w-auto ${isFilterOpen ? 'block' : 'hidden sm:flex'}`}>
            <div className={selectWrapperClass}>
              <select 
                value={selectedKeyword} 
                onChange={(e) => setSelectedKeyword(e.target.value)}
                className={selectClass}
              >
                {Array.from(keywordSet).map(keyword => (
                  <option key={keyword} value={keyword}>{keyword}</option>
                ))}
              </select>
              <ChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none text-gray-500" size={16} />
            </div>
            <div className={selectWrapperClass}>
              <select 
                value={sortOrder} 
                onChange={(e) => setSortOrder(e.target.value)}
                className={selectClass}
              >
                <option value="newest">Newest First</option>
                <option value="oldest">Oldest First</option>
              </select>
              <ChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none text-gray-500" size={16} />
            </div>
          </div>
          
          <div className="flex items-center space-x-4">
            <div className="bg-white rounded-lg p-1 flex items-center border border-gray-200">
              <button
                onClick={() => setViewMode('grid')}
                className={`p-1.5 rounded-md transition-all duration-200 ${
                  viewMode === 'grid' 
                    ? 'bg-blue-100 text-blue-600' 
                    : 'text-gray-400 hover:text-gray-600 hover:bg-gray-50'
                }`}
                title="Grid View"
              >
                <LayoutGrid className="w-4 h-4" />
              </button>
              <button
                onClick={() => setViewMode('list')}
                className={`p-1.5 rounded-md transition-all duration-200 ${
                  viewMode === 'list' 
                    ? 'bg-blue-100 text-blue-600' 
                    : 'text-gray-400 hover:text-gray-600 hover:bg-gray-50'
                }`}
                title="List View"
              >
                <ListIcon className="w-4 h-4" />
              </button>
            </div>
            <div className="text-sm text-gray-500">
              Showing {filteredAndSortedAlerts.length} alerts
            </div>
          </div>
        </div>
      </div>

      {renderContent()}
      {isLoading && <p className="text-center mt-4">Loading more alerts...</p>}
      {!isLoading && page < totalPages && (
        <div className="flex justify-center mt-4">
          <button 
            onClick={loadMoreAlerts}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300 shadow-md hover:shadow-lg"
          >
            Load More (Page {page + 1})
          </button>
        </div>
      )}
    </PageLayout>
  );
});

export default AppPage;

