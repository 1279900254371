import React from 'react';
import { motion } from 'framer-motion';
import { Mail } from 'lucide-react';

const DailyEmailUsageCard = ({ usage }) => {
  console.log('Usage prop:', usage); // Debug log

  const daily_email_limit = usage?.daily_email_limit ?? 10;
  const daily_email_count = usage?.daily_email_count ?? 0;

  const percentage = daily_email_limit > 0 ? (daily_email_count / daily_email_limit) * 100 : 0;
  
  console.log('Calculated percentage:', percentage); // Debug log

  return (
    <motion.div 
      className="bg-white shadow hover:shadow-md rounded-lg overflow-hidden border border-gray-200 p-6 h-full flex flex-col justify-between"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.2 }}
    >
      <div>
        <h2 className="text-2xl font-semibold mb-4 text-gray-800 flex items-center">
          <Mail className="w-6 h-6 mr-2" />
          Daily Email Usage
        </h2>
        <div className="space-y-3">
          <div className="relative pt-1">
            <div className="flex mb-2 items-center justify-between">
              <div>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
                  {percentage.toFixed(0)}%
                </span>
              </div>
              <div className="text-right">
                <span className="text-xs font-semibold inline-block text-green-600">
                  {daily_email_count}/{daily_email_limit}
                </span>
              </div>
            </div>
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-green-200">
              <div style={{ width: `${percentage}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
            </div>
          </div>
        </div>
      </div>
      <p className="text-sm text-gray-600">
        You've used {daily_email_count} out of your {daily_email_limit} daily emails.
      </p>
    </motion.div>
  );
};

export default DailyEmailUsageCard;
