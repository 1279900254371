import React from 'react';

export const BlogParagraph = ({ children }) => (
  <p className="text-lg text-gray-700 leading-relaxed mb-6">{children}</p>
);

export const BlogHeading = ({ children }) => (
  <h2 className="text-3xl font-bold text-gray-900 mt-8 mb-4">{children}</h2>
);

export const BlogList = ({ items, ordered = false }) => {
  const ListTag = ordered ? 'ol' : 'ul';
  return (
    <ListTag className={`${ordered ? 'list-decimal' : 'list-disc'} list-inside space-y-2 mb-6`}>
      {items.map((item, index) => (
        <li key={index} dangerouslySetInnerHTML={typeof item === 'object' ? item : { __html: item }} />
      ))}
    </ListTag>
  );
};

export const BlogInfoBox = ({ children, color = 'blue' }) => (
  <div className={`bg-${color}-100 bg-opacity-50 backdrop-filter backdrop-blur-lg border border-${color}-200 border-opacity-50 rounded-lg p-4 mb-6 shadow-lg`}>
    <p className={`text-${color}-800`}>{children}</p>
  </div>
);

export const BlogConclusion = ({ children }) => (
  <div className="bg-green-100 border-l-4 border-green-500 p-4 mt-8">
    <p className="text-green-700 font-semibold">{children}</p>
  </div>
);
