import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Navbar from '../../Navbar';

const UnsubscribePage = () => {
  const { token } = useParams();
  const [status, setStatus] = useState('processing');

  useEffect(() => {
    const unsubscribe = async () => {
      try {
        const response = await fetch(`/api/users/unsubscribe/${token}`);
        if (response.ok) {
          setStatus('success');
        } else {
          setStatus('error');
        }
      } catch (error) {
        console.error('Error unsubscribing:', error);
        setStatus('error');
      }
    };

    unsubscribe();
  }, [token]);

  return (
    <>
      <Helmet>
        <title>Unsubscribe - Notifyr</title>
        <meta name="description" content="Unsubscribe from Notifyr email notifications" />
      </Helmet>
      <div className="min-h-screen bg-gray-50">
        <Navbar />
        <main className="container mx-auto px-4 py-16 max-w-2xl">
          <div className="bg-white rounded-lg shadow p-8 text-center">
            <h1 className="text-3xl font-bold text-gray-900 mb-6">
              {status === 'processing' && 'Processing Your Request...'}
              {status === 'success' && 'Successfully Unsubscribed'}
              {status === 'error' && 'Error Processing Request'}
            </h1>
            
            {status === 'processing' && (
              <p className="text-gray-600">
                Please wait while we process your unsubscribe request...
              </p>
            )}
            
            {status === 'success' && (
              <>
                <p className="text-gray-600 mb-4">
                  You have been successfully unsubscribed from Notifyr email notifications.
                </p>
                <p className="text-gray-600">
                  You can always resubscribe to notifications from your account settings if you change your mind.
                </p>
              </>
            )}
            
            {status === 'error' && (
              <>
                <p className="text-gray-600 mb-4">
                  We encountered an error while processing your unsubscribe request.
                </p>
                <p className="text-gray-600">
                  Please try again or contact support if the problem persists.
                </p>
              </>
            )}
          </div>
        </main>
      </div>
    </>
  );
};

export default UnsubscribePage; 