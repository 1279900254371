import React, { useState, useEffect, useCallback } from 'react';
import useAuthenticatedApi from '../../../hooks/useAuthenticatedApi';
import Notification from '../../../components/Notification';
import InfoCard from './components/InfoCard';
import KeywordPerformance from './components/KeywordPerformance';
import TopKeywords from './components/TopKeywords';
import LoadingSpinner from '../components/LoadingSpinner';
import PageLayout from '../../../components/PageLayout';
import { Clock, Hash, TrendingUp } from 'lucide-react';
import KeywordListStats from './components/KeywordListStats';

const StatsPage = () => {
  const [selectedKeyword, setSelectedKeyword] = useState(null);
  const [timeRange, setTimeRange] = useState('12h');
  const [chartData, setChartData] = useState([]);
  const [keywordStats, setKeywordStats] = useState({});
  const { authFetch, useApiRequest } = useAuthenticatedApi();
  const [isLoadingKeywordStats, setIsLoadingKeywordStats] = useState(true);
  const [notification, setNotification] = useState(null);
  const [subreddits, setSubreddits] = useState([]);
  const [isLoadingSubreddits, setIsLoadingSubreddits] = useState(true);
  const [subredditsError, setSubredditsError] = useState(null);

  const fetchTopKeywords = useCallback(async () => {
    const data = await authFetch('api/stats/top_keywords');
    return data;
  }, [authFetch]);

  const { data: topKeywords, isLoading: isLoadingKeywords, error: keywordsError } = useApiRequest(fetchTopKeywords);

  useEffect(() => {
    if (topKeywords && topKeywords.length > 0) {
      setSelectedKeyword(topKeywords[0]);
    }
  }, [topKeywords]);

  const showNotification = (message, type = 'error') => {
    setNotification({ message, type });
  };

  const hideNotification = () => {
    setNotification(null);
  };

  useEffect(() => {
    const fetchKeywordPerformance = async () => {
      if (selectedKeyword) {
        setIsLoadingKeywordStats(true);
        try {
          const data = await authFetch(`api/stats/keyword_performance?keyword_id=${selectedKeyword.id}&time_range=${timeRange}`);
          setChartData(data.time_series);
          setKeywordStats({
            totalMatches: data.total_matches,
            avgProcessingTime: data.avg_processing_time,
            matchesPerHour: data.matches_per_hour
          });
        } catch (error) {
          console.error('Error fetching keyword performance:', error);
          showNotification('Failed to fetch keyword performance data. Please try again later.');
        } finally {
          setIsLoadingKeywordStats(false);
        }
      }
    };

    fetchKeywordPerformance();
  }, [selectedKeyword, timeRange, authFetch]);

  useEffect(() => {
    const fetchKeywordList = async () => {
      try {
        setIsLoadingSubreddits(true);
        const data = await authFetch('api/stats/keyword_list');
        setSubreddits(data);
      } catch (err) {
        setSubredditsError('Failed to load keyword statistics');
        console.error('Error fetching keyword list:', err);
      } finally {
        setIsLoadingSubreddits(false);
      }
    };

    fetchKeywordList();
  }, [authFetch]);

  const handleTimeRangeChange = async (newRange) => {
    setChartData(null);
    setTimeRange(newRange);
    
    if (selectedKeyword) {
      setIsLoadingKeywordStats(true);
      try {
        const data = await authFetch(`api/stats/keyword_performance?keyword_id=${selectedKeyword.id}&time_range=${newRange}`);
        setChartData(data.time_series);
        setKeywordStats({
          totalMatches: data.total_matches,
          avgProcessingTime: data.avg_processing_time,
          matchesPerHour: data.matches_per_hour
        });
      } catch (error) {
        console.error('Error fetching keyword performance:', error);
        showNotification('Failed to fetch keyword performance data. Please try again later.');
      } finally {
        setIsLoadingKeywordStats(false);
      }
    }
  };

  if (isLoadingKeywords) {
    return (
      <PageLayout 
        title="Statistics" 
        description="View your Reddit keyword alert statistics with Notifyr."
        breadcrumbs={[
          { label: 'Dashboard', href: '/' },
          { label: 'Statistics' }
        ]}
      >
        <div className="flex-grow flex items-center justify-center">
          <LoadingSpinner size="large" />
        </div>
      </PageLayout>
    );
  }

  if (keywordsError) {
    showNotification(`Error loading data: ${keywordsError}`);
    return (
      <PageLayout 
        title="Statistics" 
        description="View your Reddit keyword alert statistics with Notifyr."
        breadcrumbs={[
          { label: 'Dashboard', href: '/' },
          { label: 'Statistics' }
        ]}
      >
        <div className="flex items-center justify-center">
          <p>An error occurred. Please try refreshing the page.</p>
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout 
      title="Statistics" 
      description="View your Reddit keyword alert statistics with Notifyr."
      breadcrumbs={[
        { label: 'Dashboard', href: '/' },
        { label: 'Statistics' }
      ]}
      className="max-w-7xl"
    >
      <div className="flex flex-col lg:flex-row gap-8">
        <div className="flex-grow lg:w-3/4 flex flex-col">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            <InfoCard 
              icon={<TrendingUp />}
              title="Matches per Hour"
              value={keywordStats.matchesPerHour?.toFixed(2) || "N/A"}
              isLoading={isLoadingKeywordStats}
            />
            <InfoCard 
              icon={<Hash />}
              title={`Total Matches (${timeRange})`}
              value={keywordStats.totalMatches || "N/A"}
              isLoading={isLoadingKeywordStats}
            />
            <InfoCard 
              icon={<Clock />}
              title="Avg. Processing Time"
              value={`${keywordStats.avgProcessingTime?.toFixed(2) || "N/A"}s`}
              isLoading={isLoadingKeywordStats}
            />
          </div>
          
          {selectedKeyword && (
            <KeywordPerformance
              selectedKeyword={selectedKeyword}
              timeRange={timeRange}
              chartData={chartData}
              handleTimeRangeChange={handleTimeRangeChange}
            />
          )}
        </div>
        
        <TopKeywords
          topKeywords={topKeywords}
          selectedKeyword={selectedKeyword}
          setSelectedKeyword={setSelectedKeyword}
        />
      </div>

      <KeywordListStats 
        subreddits={subreddits}
        isLoading={isLoadingSubreddits}
        error={subredditsError}
      />

      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={hideNotification}
        />
      )}
    </PageLayout>
  );
};

export default StatsPage;
