import React from 'react';
import { motion } from 'framer-motion';
import { Bell, Inbox, Zap, Heart } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footers';

const AboutPage = () => {
  return (
    <>
      <Helmet>
        <title>About Notifyr - Your Reddit Monitoring Solution</title>
        <meta name="description" content="Learn about Notifyr, a powerful tool for monitoring Reddit posts and comments. Get instant notifications for keywords in your favorite subreddits." />
        <meta name="keywords" content="Notifyr, about, monitoring, notifications, keywords, subreddits, Reddit alerts" />
      </Helmet>
      <div className="min-h-screen bg-gradient-to-b from-slate-50 to-blue-50">
        <Navbar />
        <div className="relative py-24 sm:py-32">
          <div className="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,transparent,black)] pointer-events-none"></div>
          <div className="max-w-4xl mx-auto px-6 sm:px-6 lg:px-8 relative z-10">
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-center mb-16"
            >
              <motion.div 
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                className="inline-block mb-6 px-4 py-2 rounded-full bg-blue-50 text-blue-600 text-sm font-semibold border border-blue-100 shadow-sm glow-blue"
              >
                <span className="mr-2">👋</span>
                <span>Nice to meet you!</span>
              </motion.div>

              <h1 className="text-4xl sm:text-5xl font-extrabold text-gray-900 mb-4">
                About Notifyr
              </h1>
              
              <p className="text-lg sm:text-xl text-gray-600 mb-12 max-w-2xl mx-auto">
                Your smart companion for never missing important Reddit content again
              </p>
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="bg-white/80 backdrop-blur-sm rounded-xl shadow-xl overflow-hidden border border-blue-50"
            >
              <div className="p-8 sm:p-10">
                <div className="mb-12">
                  <h2 className="text-2xl font-bold text-gray-900 mb-6">Our Story</h2>
                  <div className="space-y-6">
                    <p className="text-gray-700 leading-relaxed">
                      Hey there! We're just like you – Reddit enthusiasts who love discovering great content and amazing deals. We found ourselves constantly checking subreddits like r/pkmntcgtrades and r/GunAccessoriesForSale, hoping to catch that perfect post or deal before anyone else.
                    </p>
                    <p className="text-gray-700 leading-relaxed">
                      You know that feeling when you find out you just missed an incredible deal by a few minutes? Yeah, we've been there too many times. That's when we thought, "There has to be a better way!" And that's how Notifyr was born – a simple but powerful tool to keep you in the loop.
                    </p>
                    <p className="text-gray-700 leading-relaxed">
                      What started as our personal solution has grown into something much bigger. Now, Redditors from all kinds of communities are using Notifyr to stay on top of the content they care about, whether it's trading cards, tech deals, or their favorite discussion topics.
                    </p>
                  </div>
                </div>

                <div>
                  <h2 className="text-2xl font-bold text-gray-900 mb-8">What Makes Us Special</h2>
                  <div className="grid gap-6 max-w-3xl mx-auto">
                    <Feature 
                      icon={<Bell className="text-blue-500" />} 
                      title="Smart Alerts That Work For You"
                    >
                      Create custom alerts for any keywords you're interested in, across any subreddit. It's like having a friend who never sleeps, always watching for the posts you care about.
                    </Feature>
                    <Feature 
                      icon={<Inbox className="text-blue-500" />} 
                      title="Organized Updates"
                    >
                      No more notification overload! We bundle similar alerts together so you stay informed without getting overwhelmed.
                    </Feature>
                    <Feature 
                      icon={<Zap className="text-blue-500" />} 
                      title="Lightning Fast"
                    >
                      When that perfect post appears, you'll know about it right away. Our system is optimized for speed because timing is everything.
                    </Feature>
                    <Feature 
                      icon={<Heart className="text-blue-500" />} 
                      title="Built Together"
                    >
                      We're constantly improving Notifyr based on feedback from users like you. Got ideas? We'd love to hear them!
                      <Link to="/contact" className="inline-flex items-center mt-3 text-blue-600 hover:text-blue-700 font-medium group">
                        Give Feedback
                        <svg 
                          className="ml-1 w-4 h-4 transform group-hover:translate-x-1 transition-transform duration-200" 
                          fill="none" 
                          viewBox="0 0 24 24" 
                          stroke="currentColor"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                      </Link>
                    </Feature>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

const Feature = ({ icon, title, children }) => (
  <motion.li
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="flex items-start p-6 bg-blue-50/50 backdrop-blur-sm rounded-xl border border-blue-100/50 hover:border-blue-200/50 transition-all duration-300"
  >
    <div className="flex-shrink-0 mr-4 p-2 bg-white rounded-lg shadow-sm">
      {React.cloneElement(icon, { size: 24 })}
    </div>
    <div>
      <h3 className="text-xl font-semibold text-gray-900 mb-2">{title}</h3>
      <div className="text-gray-700 leading-relaxed">{children}</div>
    </div>
  </motion.li>
);

export default AboutPage;