import React, { useState } from 'react';
import { Hash, Clock, Edit2, Trash2, ChevronDown, Plus } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import Modal from '../../../components/Modal';

const KeywordListItem = ({ keyword, keywordId, subreddit, onEdit, onDelete }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    try {
      await onDelete(subreddit, keywordId);
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error("Error deleting keyword:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <>
      <div className={`
        bg-gradient-to-br from-white to-gray-50
        border border-gray-200 rounded-lg shadow-sm hover:shadow-md 
        transition-all duration-200 mb-3 overflow-hidden
        hover:border-blue-200
      `}>
        <div className="p-4">
          {/* Header Section */}
          <div className="flex items-center justify-between gap-2 mb-3">
            <div className="flex items-center gap-2 min-w-0">
              <span className="font-semibold text-sm bg-gradient-to-r from-blue-500 to-blue-600 text-white px-3 py-1 rounded-md shadow-sm truncate max-w-[80px] sm:max-w-[120px]">
                {keyword.keyword}
              </span>
              <div className="flex items-center gap-2">
                <div className="flex items-center bg-gray-100 text-xs text-gray-600 px-2 py-1 rounded-md">
                  <Clock className="w-3 h-3 mr-1 text-blue-500" />
                  {keyword.batch_time === "0" ? "Instant" : keyword.batch_time}
                </div>
                <div className={`
                  flex items-center text-xs px-2 py-1 rounded-md
                  ${keyword.enabled 
                    ? 'bg-green-50 text-green-700 border border-green-100' 
                    : 'bg-red-50 text-red-700 border border-red-100'}
                `}>
                  <div className={`w-2 h-2 rounded-full ${keyword.enabled ? 'bg-green-400' : 'bg-red-400'}`}></div>
                  <span className="hidden sm:inline ml-1.5">{keyword.enabled ? 'Enabled' : 'Disabled'}</span>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <button 
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit(subreddit, keywordId);
                }} 
                className="text-blue-600 hover:bg-blue-50 p-1.5 rounded-md transition-colors"
              >
                <Edit2 className="w-4 h-4" />
              </button>
              <button 
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteClick();
                }} 
                className="text-red-600 hover:bg-red-50 p-1.5 rounded-md transition-colors"
                disabled={isDeleting}
              >
                <Trash2 className="w-4 h-4" />
              </button>
            </div>
          </div>

          {/* Conditions Section */}
          <div className={`
            text-sm text-gray-700 bg-gradient-to-br from-gray-50 to-white
            border border-gray-100 p-4 rounded-lg
          `}>
            {keyword.conditions.AND.length > 0 && (
              <div className="flex items-center flex-wrap gap-1.5 mb-2">
                <span className="text-xs font-medium text-gray-500">AND:</span>
                <AnimatePresence>
                  {keyword.conditions.AND.map((condition, index) => (
                    <motion.span 
                      key={index}
                      initial={{ opacity: 0, y: 4 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 4 }}
                      transition={{ 
                        duration: 0.15,
                        delay: index * 0.05,
                        ease: "easeOut"
                      }}
                      className="bg-gradient-to-br from-gray-50 to-gray-100 border border-gray-200 text-gray-700 text-xs px-2.5 py-1 rounded-md font-medium shadow-sm"
                    >
                      {condition}
                    </motion.span>
                  ))}
                </AnimatePresence>
              </div>
            )}
            {keyword.conditions.NOT.length > 0 && (
              <div className="flex items-center flex-wrap gap-1.5">
                <span className="text-xs font-medium text-gray-500">NOT:</span>
                <AnimatePresence>
                  {keyword.conditions.NOT.map((condition, index) => (
                    <motion.span 
                      key={index}
                      initial={{ opacity: 0, y: 4 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 4 }}
                      transition={{ 
                        duration: 0.15,
                        delay: index * 0.05,
                        ease: "easeOut"
                      }}
                      className="bg-gradient-to-br from-gray-50 to-gray-100 border border-gray-200 text-gray-700 text-xs px-2.5 py-1 rounded-md font-medium shadow-sm"
                    >
                      {condition}
                    </motion.span>
                  ))}
                </AnimatePresence>
              </div>
            )}
          </div>

          {/* Notifications Section */}
          <div className="flex items-center space-x-2 mt-3">
            <div className={`flex items-center ${keyword.notify_email ? 'bg-blue-50 text-blue-700 border-blue-100' : 'bg-gray-50 text-gray-500 border-gray-200'} text-xs px-2.5 py-1 rounded-md border`}>
              <svg className="w-3.5 h-3.5 mr-1.5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"/>
                <polyline points="22,6 12,13 2,6"/>
              </svg>
              Email
            </div>
            <div className={`flex items-center ${keyword.notify_push ? 'bg-[#5865F2] text-white border-[#5865F2]' : 'bg-gray-50 text-gray-500 border-gray-200'} text-xs px-2.5 py-1 rounded-md border`}>
              <svg className="w-3.5 h-3.5 mr-1.5" viewBox="0 0 71 55" fill={keyword.notify_push ? 'currentColor' : 'currentColor'}>
                <g>
                  <path d="M60.1045 4.8978C55.5792 2.8214 50.7265 1.2916 45.6527 0.41542C45.5603 0.39851 45.468 0.440769 45.4204 0.525289C44.7963 1.6353 44.105 3.0834 43.6209 4.2216C38.1637 3.4046 32.7345 3.4046 27.3892 4.2216C26.905 3.0581 26.1886 1.6353 25.5617 0.525289C25.5141 0.443589 25.4218 0.40133 25.3294 0.41542C20.2584 1.2888 15.4057 2.8186 10.8776 4.8978C10.8384 4.9147 10.8048 4.9429 10.7825 4.9795C1.57795 18.7309 -0.943561 32.1443 0.293408 45.3914C0.299005 45.4562 0.335386 45.5182 0.385761 45.5576C6.45866 50.0174 12.3413 52.7249 18.1147 54.5195C18.2071 54.5477 18.305 54.5139 18.3638 54.4378C19.7295 52.5728 20.9469 50.6063 21.9907 48.5383C22.0523 48.4172 21.9935 48.2735 21.8676 48.2256C19.9366 47.4931 18.0979 46.6 16.3292 45.5858C16.1893 45.5041 16.1781 45.304 16.3068 45.2082C16.679 44.9293 17.0513 44.6391 17.4067 44.3461C17.471 44.2926 17.5606 44.2813 17.6362 44.3151C29.2558 49.6202 41.8354 49.6202 53.3179 44.3151C53.3935 44.2785 53.4831 44.2898 53.5502 44.3433C53.9057 44.6363 54.2779 44.9293 54.6529 45.2082C54.7816 45.304 54.7732 45.5041 54.6333 45.5858C52.8646 46.6197 51.0259 47.4931 49.0921 48.2228C48.9662 48.2707 48.9102 48.4172 48.9718 48.5383C50.038 50.6034 51.2554 52.5699 52.5959 54.435C52.6519 54.5139 52.7526 54.5477 52.845 54.5195C58.6464 52.7249 64.529 50.0174 70.6019 45.5576C70.6551 45.5182 70.6887 45.459 70.6943 45.3942C72.1747 30.0791 68.2147 16.7757 60.1968 4.9823C60.1772 4.9429 60.1437 4.9147 60.1045 4.8978Z"/>
                  <path className={keyword.notify_push ? 'fill-[#5865F2]' : 'fill-gray-50'} d="M23.7259 19.7759C20.2276 19.7759 17.4451 22.8356 17.4451 26.6889C17.4451 30.5423 20.2849 33.6019 23.7259 33.6019C27.2241 33.6019 30.0066 30.5423 30.0066 26.6889C30.0638 22.8356 27.2241 19.7759 23.7259 19.7759Z"/>
                  <path className={keyword.notify_push ? 'fill-[#5865F2]' : 'fill-gray-50'} d="M47.3178 19.7759C43.8196 19.7759 41.0371 22.8356 41.0371 26.6889C41.0371 30.5423 43.8769 33.6019 47.3178 33.6019C50.816 33.6019 53.5986 30.5423 53.5986 26.6889C53.5986 22.8356 50.816 19.7759 47.3178 19.7759Z"/>
                </g>
              </svg>
              Discord
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <h2 className="text-2xl font-bold mb-4">Confirm Deletion</h2>
        <p className="mb-4">Are you sure you want to delete this keyword?</p>
        <div className="flex justify-end space-x-2">
          <button 
            onClick={() => setIsDeleteModalOpen(false)}
            className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 rounded-md transition-colors duration-200"
          >
            Cancel
          </button>
          <button
            onClick={handleConfirmDelete}
            disabled={isDeleting}
            className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-md transition-colors duration-200"
          >
            {isDeleting ? 'Deleting...' : 'Delete'}
          </button>
        </div>
      </Modal>
    </>
  );
};

const SubredditSection = ({ subreddit, keywords, onEditKeyword, onDeleteKeyword, onAddKeyword, maxKeywords, onDelete }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const keywordCount = Object.keys(keywords).length;
  const enabledCount = Object.values(keywords).filter(k => k.enabled).length;

  return (
    <motion.div 
      className="mb-4 last:mb-0"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div className={`
        bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden
        transition-all duration-200
        ${isExpanded ? 'shadow-md border-blue-200' : 'hover:shadow-md hover:border-blue-200'}
      `}>
        <div 
          className="p-4 cursor-pointer border-b border-gray-100"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3 min-w-0 flex-1">
              <motion.span 
                initial={false}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.1 }}
                className="font-semibold text-sm bg-gradient-to-r from-blue-500 to-blue-600 text-white px-3 py-1 rounded-md shadow-sm truncate max-w-[125px] sm:max-w-[200px]"
              >
                r/{subreddit}
              </motion.span>
              <div className="flex items-center space-x-2 flex-shrink-0">
                <motion.div 
                  initial={false}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: 0.2 }}
                  className="flex items-center bg-gray-100 text-xs text-gray-600 px-2 py-1 rounded-md"
                >
                  <Hash className="w-3 h-3 mr-1 text-blue-500" />
                  <span>{keywordCount} / {maxKeywords}</span>
                </motion.div>
                {!isExpanded && (
                  <AnimatePresence>
                    <motion.div 
                      initial={{ opacity: 0, y: 4 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 4 }}
                      transition={{ duration: 0.2, ease: "easeOut" }}
                      className="hidden sm:flex items-center bg-green-50 text-xs text-green-700 px-2 py-1 rounded-md border border-green-100"
                    >
                      <div className="w-2 h-2 rounded-full bg-green-400 mr-1.5"></div>
                      {enabledCount} active
                    </motion.div>
                  </AnimatePresence>
                )}
              </div>
            </div>
            <div className="flex items-center gap-2 flex-shrink-0">
              {keywordCount < maxKeywords && (
                <motion.button
                  initial={false}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: 0.3 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onAddKeyword(subreddit);
                  }}
                  className="bg-blue-50 text-blue-600 hover:bg-blue-100 p-1.5 rounded-md transition-colors border border-blue-200"
                >
                  <Plus className="w-4 h-4" />
                </motion.button>
              )}
              <motion.button
                initial={false}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.3 }}
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(subreddit);
                }}
                className="text-red-600 hover:bg-red-50 p-1.5 rounded-md transition-colors"
              >
                <Trash2 className="w-4 h-4" />
              </motion.button>
              <div 
                className={`
                  p-1.5 rounded-full transition-colors
                  ${isExpanded 
                    ? 'bg-blue-100 text-blue-600 border border-blue-200' 
                    : 'bg-gray-100 text-gray-500 border border-gray-200 hover:bg-gray-200'
                  }
                `}
              >
                <motion.div
                  animate={{ rotate: isExpanded ? 180 : 0 }}
                  transition={{ duration: 0.2, ease: "easeInOut" }}
                >
                  <ChevronDown className="w-4 h-4" />
                </motion.div>
              </div>
            </div>
          </div>
        </div>

        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.2 }}
              className="overflow-hidden"
            >
              <div className="p-4 space-y-3">
                {Object.entries(keywords).map(([keywordId, keyword]) => (
                  <KeywordListItem
                    key={keywordId}
                    keyword={keyword}
                    keywordId={keywordId}
                    subreddit={subreddit}
                    onEdit={onEditKeyword}
                    onDelete={onDeleteKeyword}
                  />
                ))}
                {keywordCount < maxKeywords && (
                  <button
                    onClick={() => onAddKeyword(subreddit)}
                    className="w-full bg-white hover:bg-gray-50 border-2 border-dashed border-gray-200 hover:border-blue-200 rounded-lg p-4 transition-all duration-200 flex items-center justify-center gap-2 text-gray-500 hover:text-blue-600 group"
                  >
                    <Plus className="w-5 h-5 transition-transform group-hover:scale-110" />
                    <span className="font-medium">Add Keyword</span>
                  </button>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

const KeywordList = ({ subreddits, onEditKeyword, onDeleteKeyword, onAddKeyword, maxKeywords, onDelete }) => {
  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="space-y-6"
    >
      {Object.entries(subreddits).map(([subreddit, keywords]) => (
        <SubredditSection
          key={subreddit}
          subreddit={subreddit}
          keywords={keywords}
          onEditKeyword={onEditKeyword}
          onDeleteKeyword={onDeleteKeyword}
          onAddKeyword={onAddKeyword}
          maxKeywords={maxKeywords}
          onDelete={() => onDelete(subreddit)}
        />
      ))}
    </motion.div>
  );
};

export default KeywordList;