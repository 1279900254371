import React, { useState, useEffect, useCallback } from 'react';
import { Copy, CheckCircle, XCircle } from 'lucide-react';

const DiscordVerification = ({ user, authFetch, showNotification }) => {
  const [isVerified, setIsVerified] = useState(false);
  const [discordUsername, setDiscordUsername] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [verificationCode, setVerificationCode] = useState(null);
  const [expirationTime, setExpirationTime] = useState(null);
  const [isGeneratingCode, setIsGeneratingCode] = useState(false);
  const [isUnlinking, setIsUnlinking] = useState(false);

  const fetchDiscordStatus = useCallback(async () => {
    setIsLoading(true);
    try {
      // Check if we have cached data
      const cachedData = localStorage.getItem(`discordVerification_${user.id}`);
      if (cachedData) {
        const { verified, discord_username, timestamp } = JSON.parse(cachedData);
        const now = Date.now();
        const threeMinutes = 3 * 60 * 1000;
        
        // Check if cache is still valid (less than 3 minutes old)
        if (now - timestamp < threeMinutes) {
          setIsVerified(verified);
          setDiscordUsername(discord_username);
          setIsLoading(false);
          return;
        }
        // Cache expired, remove it
        localStorage.removeItem(`discordVerification_${user.id}`);
      }

      // If no cached data or cache expired, fetch from API
      const response = await authFetch(`/api/users/discord-verification-status?uuid=${user.id}`);
      setIsVerified(response.verified);
      setDiscordUsername(response.discord_username);

      // Cache the result with timestamp
      localStorage.setItem(`discordVerification_${user.id}`, JSON.stringify({
        verified: response.verified,
        discord_username: response.discord_username,
        timestamp: Date.now()
      }));
    } catch (error) {
      console.error('Error fetching Discord status:', error);
      showNotification('Failed to fetch Discord status', 'error');
    } finally {
      setIsLoading(false);
    }
  }, [authFetch, user.id, showNotification]);

  const clearVerificationCode = useCallback(() => {
    setVerificationCode(null);
    setExpirationTime(null);
    localStorage.removeItem('discordVerificationCode');
    localStorage.removeItem('discordVerificationExpiry');
  }, []);

  const checkStoredVerificationCode = useCallback(() => {
    const storedCode = localStorage.getItem('discordVerificationCode');
    const storedExpiry = localStorage.getItem('discordVerificationExpiry');
    
    if (storedCode && storedExpiry) {
      const now = new Date().getTime();
      const expiryTime = parseInt(storedExpiry);
      
      if (now < expiryTime) {
        setVerificationCode(storedCode);
        setExpirationTime(new Date(expiryTime));
        
        // Set up expiration timer
        const timeUntilExpiry = expiryTime - now;
        setTimeout(() => {
          clearVerificationCode();
        }, timeUntilExpiry);
      } else {
        clearVerificationCode();
      }
    }
  }, [clearVerificationCode]);

  useEffect(() => {
    fetchDiscordStatus();
    checkStoredVerificationCode();
  }, [fetchDiscordStatus, checkStoredVerificationCode]);

  const handleGenerateCode = async () => {
    setIsGeneratingCode(true);
    try {
      const response = await authFetch('/api/users/generate-discord-verification', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ uuid: user.id }),
      });
      
      const code = response.code;
      const expiryTime = new Date(Date.now() + 15 * 60 * 1000).getTime(); // 15 minutes from now
      
      setVerificationCode(code);
      setExpirationTime(new Date(expiryTime));
      
      localStorage.setItem('discordVerificationCode', code);
      localStorage.setItem('discordVerificationExpiry', expiryTime.toString());
      
      // Set up expiration timer
      setTimeout(() => {
        clearVerificationCode();
      }, 15 * 60 * 1000);
    } catch (error) {
      console.error('Error generating verification code:', error);
      showNotification('Failed to generate verification code', 'error');
    } finally {
      setIsGeneratingCode(false);
    }
  };

  const handleUnlinkDiscord = async () => {
    setIsUnlinking(true);
    try {
      await authFetch('/api/users/unlink-discord', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ uuid: user.id }),
      });
      showNotification('Discord account unlinked successfully', 'success');
      setIsVerified(false);
      setDiscordUsername(null);
      localStorage.removeItem('discordVerificationCode');
      localStorage.removeItem('discordVerificationExpiry');
      // Clear the cached verification status
      localStorage.removeItem(`discordVerification_${user.id}`);
      setVerificationCode(null);
      setExpirationTime(null);
    } catch (error) {
      console.error('Error unlinking Discord account:', error);
      showNotification('Failed to unlink Discord account', 'error');
    } finally {
      setIsUnlinking(false);
    }
  };

  const formatExpirationTime = (time) => {
    return new Date(time).toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short'
    });
  };

  const handleCopyCode = () => {
    navigator.clipboard.writeText(verificationCode).then(() => {
      showNotification('Verification code copied to clipboard', 'success');
    }, (err) => {
      console.error('Could not copy text: ', err);
      showNotification('Failed to copy verification code', 'error');
    });
  };

  if (isLoading) {
    return (
      <div className="bg-[#5865F2] shadow-lg rounded-lg overflow-hidden p-6">
        <div className="flex items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white"></div>
        </div>
        <p className="text-white text-center mt-4">Loading Discord verification status...</p>
      </div>
    );
  }

  return (
    <div className="bg-[#5865F2] shadow-lg rounded-lg overflow-hidden">
      <div className="bg-[#4752C4] p-4">
        <h2 className="text-xl font-bold text-white mb-1">Discord Verification</h2>
        <p className="text-[#B9BFFE] text-sm">Connect your Discord account for notifications</p>
      </div>
      <div className="p-6">
        {isVerified ? (
          <div className="flex items-start space-x-4">
            <CheckCircle size={32} className="text-green-400 flex-shrink-0 mt-1" />
            <div>
              <p className="text-white text-lg font-semibold mb-2">
                Discord Account Linked
              </p>
              <p className="text-[#B9BFFE] mb-4">
                Username: <span className="font-bold text-white bg-[#4752C4] px-2 py-1 rounded">{discordUsername}</span>
              </p>
              <button
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-300 text-sm flex items-center"
                onClick={handleUnlinkDiscord}
                disabled={isUnlinking}
              >
                {isUnlinking ? (
                  <>
                    <Spinner className="mr-2" />
                    Unlinking...
                  </>
                ) : (
                  'Unlink Discord Account'
                )}
              </button>
            </div>
          </div>
        ) : (
          <div>
            {verificationCode ? (
              <div className="mb-4">
                <p className="text-white mb-2">Your verification code is:</p>
                <div className="flex items-center bg-white rounded p-2 mb-2">
                  <p className="text-2xl font-bold text-[#5865F2] mr-2">{verificationCode}</p>
                  <button
                    onClick={handleCopyCode}
                    className="text-gray-500 hover:text-gray-700 transition duration-300"
                    title="Copy to clipboard"
                  >
                    <Copy size={20} />
                  </button>
                </div>
                <p className="text-[#B9BFFE] text-sm">
                  This code will expire on {formatExpirationTime(expirationTime)}.
                </p>
              </div>
            ) : (
              <div className="flex items-start space-x-4">
                <XCircle size={32} className="text-red-400 flex-shrink-0 mt-1" />
                <div>
                  <p className="text-white text-lg font-semibold mb-2">No Discord account linked</p>
                  <button
                    className="bg-[#3BA55C] hover:bg-[#2D7D46] text-white font-bold py-2 px-4 rounded transition duration-300 text-sm flex items-center"
                    onClick={handleGenerateCode}
                    disabled={isGeneratingCode}
                  >
                    {isGeneratingCode ? (
                      <>
                        <Spinner className="mr-2" />
                        Generating...
                      </>
                    ) : (
                      'Generate Verification Code'
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

// New modern spinner component
const Spinner = ({ className = "" }) => (
  <svg className={`animate-spin h-5 w-5 text-white ${className}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>
);

export default DiscordVerification;
