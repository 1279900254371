import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { User, Lock } from 'lucide-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../../../AuthContext';
import { FormBackgroundWithPattern, TestimonialBackgroundWithPattern } from '../../../components/AuthBackgrounds';
import Navbar from '../../../components/Navbar';
import FeatureShowcase from '../../../components/FeatureShowcase';
import supabase from '../../../supabase/supabaseClient';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDiscordLoading, setIsDiscordLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { login, loginWithDiscord, registerDiscordUser } = useAuth();

  useEffect(() => {
    const checkAndRegisterDiscordUser = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        
        if (!session) {
          console.log('No active session found');
          return;
        }

        if (session?.user?.app_metadata?.provider === 'discord') {
          console.log('Discord user detected, attempting registration');
          setIsLoading(true);
          
          try {
            await registerDiscordUser(session);
            console.log('Discord registration successful');
            const params = new URLSearchParams(location.search);
            const redirectUrl = params.get('redirect') || '/alerts';
            navigate(decodeURIComponent(redirectUrl));
          } catch (error) {
            console.error('Error registering Discord user:', error);
            setLoginError('Failed to complete Discord registration. Please try again or contact support.');
            // Optionally sign out the user if registration fails
            await supabase.auth.signOut();
          }
        }
      } catch (error) {
        console.error('Session check error:', error);
        setLoginError('Authentication error. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    checkAndRegisterDiscordUser();
  }, [registerDiscordUser, location.search, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await login(email, password);
      const params = new URLSearchParams(location.search);
      const redirectUrl = params.get('redirect') || '/alerts';
      navigate(decodeURIComponent(redirectUrl));
    } catch (error) {
      console.error('Error logging in:', error.message);
      setLoginError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDiscordLogin = async () => {
    setIsDiscordLoading(true);
    try {
      await loginWithDiscord();
    } catch (error) {
      console.error('Discord login error:', error);
      setLoginError('Failed to login with Discord. Please try again.');
      setIsDiscordLoading(false);
    }
  };

  const renderForm = () => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-white rounded-lg shadow-xl p-8 w-full max-w-md relative z-10"
    >
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Welcome Back</h2>
      <form onSubmit={handleLogin}>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-700">Email</label>
          <div className="relative">
            <User className="absolute left-3 top-3 text-gray-400" size={18} />
            <input
              type="email"
              className="w-full pl-10 pr-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white bg-opacity-80"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </div>
        </div>
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-700">Password</label>
          <div className="relative">
            <Lock className="absolute left-3 top-3 text-gray-400" size={18} />
            <input
              type="password"
              className="w-full pl-10 pr-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white bg-opacity-80"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              required
            />
          </div>
        </div>
        <button
          type="submit"
          className="w-full bg-blue-600 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300 flex items-center justify-center"
          disabled={isLoading}
        >
          {isLoading ? (
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          ) : null}
          {isLoading ? 'Logging in...' : 'Login'}
        </button>
      </form>
      {loginError && <p className="text-red-500 text-center mt-4">{loginError}</p>}
      
      <div className="mt-4">
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">
              Or continue with
            </span>
          </div>
        </div>

        <button
          type="button"
          onClick={handleDiscordLogin}
          disabled={isDiscordLoading}
          className="mt-4 w-full flex justify-center items-center gap-2 bg-[#5865F2] text-white px-4 py-2 rounded-lg hover:bg-[#4752C4] transition-colors disabled:opacity-70 disabled:cursor-not-allowed"
        >
          {isDiscordLoading ? (
            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          ) : (
            <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z" />
            </svg>
          )}
          {isDiscordLoading ? 'Connecting...' : 'Sign in with Discord'}
        </button>
      </div>

      <p className="mt-4 text-center text-gray-600">
        Don't have an account?
        <Link to={`/register${location.search}`} className="ml-2 text-blue-600 hover:underline">
          Register
        </Link>
      </p>
    </motion.div>
  );

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={location.pathname}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <Helmet>
          <title>Log In - Notifyr</title>
          <meta name="description" content="Log in to your Notifyr account to manage your keyword alerts and notifications for Reddit posts and comments." />
          <meta name="keywords" content="login, sign in, Notifyr, account access, Reddit alerts" />
        </Helmet>
        <div className="min-h-screen flex flex-col">
          <Navbar />
          <div className="flex-grow flex">
            <div className="hidden lg:flex w-1/2 items-center justify-center p-12 relative overflow-hidden">
              <TestimonialBackgroundWithPattern />
              <div className="relative z-10">
                <FeatureShowcase />
              </div>
            </div>
            <div className="w-full lg:w-1/2 flex items-center justify-center p-4 lg:p-12 relative overflow-hidden">
              <FormBackgroundWithPattern />
              <div className="relative z-10 w-full max-w-md pt-16">
                {renderForm()}
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default LoginPage;
