import React from 'react';
import { motion } from 'framer-motion';
import { Zap, Search, BarChart } from 'lucide-react';

const features = [
  {
    icon: <Zap className="w-6 h-6" />,
    title: "Lightning Fast Alerts",
    description: "Get notified within seconds of new posts matching your keywords"
  },
  {
    icon: <Search className="w-6 h-6" />,
    title: "Advanced Filtering",
    description: "Fine-tune your searches with powerful filtering options"
  },
  {
    icon: <BarChart className="w-6 h-6" />,
    title: "Detailed Analytics",
    description: "Track your keyword performance with in-depth insights"
  }
];

const FeatureShowcase = () => {
  return (
    <div className="w-full max-w-md pt-16">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-white bg-opacity-95 rounded-lg shadow-lg p-8"
      >
        <h2 className="text-2xl font-bold text-blue-900 mb-8">
          Why Choose Our Platform?
        </h2>
        <div className="space-y-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="flex items-start space-x-4"
            >
              <div className="flex-shrink-0 w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center text-blue-600">
                {feature.icon}
              </div>
              <div className="flex-1">
                <h3 className="text-lg font-semibold text-blue-900 mb-2">
                  {feature.title}
                </h3>
                <p className="text-blue-700 opacity-80 text-base">
                  {feature.description}
                </p>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default FeatureShowcase; 