import React, { useState } from 'react';
import { useAuth } from '../../../AuthContext';
import Modal from '../../../components/Modal';
import Notification from '../../../components/Notification';
import useAuthenticatedApi from '../../../hooks/useAuthenticatedApi';
import SubscriptionCard from './SubscriptionCard';
import ProfileCard from './ProfileCard';
import DiscordVerification from './DiscordVerification';
import { Info, ArrowDownCircle, Loader2 } from 'lucide-react';
import DailyEmailUsageCard from './DailyEmailUsageCard';
import LoadingSpinner from '../components/LoadingSpinner';
import EmailPreferences from './EmailPreferences';
import PageLayout from '../../../components/PageLayout';

const AccountPage = () => {
  const { user } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notification, setNotification] = useState(null);
  const { authFetch, useApiRequest } = useAuthenticatedApi();
  const [isCancelling, setIsCancelling] = useState(false);
  const [isRemovingCancellation, setIsRemovingCancellation] = useState(false);
  const [cancellationError, setCancellationError] = useState(null);
  const [isDowngrading, setIsDowngrading] = useState(false);

  const { data: subscription, isLoading, error, refetch: refetchSubscription } = useApiRequest(
    async () => {
      if (!user?.id) return null;
      return await authFetch(`/api/users/subscription?uuid=${user.id}`);
    },
    [user?.id]
  );

  const showNotification = (message, type = 'info') => {
    setNotification({ message, type });
  };

  const handleCancelSubscription = async () => {
    if (!user || !user.id) return;
    setIsCancelling(true);
    setCancellationError(null);

    try {
      await authFetch(`/api/users/subscription/cancel?uuid=${user.id}`, {
        method: 'POST',
      });

      await refetchSubscription();
      setIsModalOpen(false);
      showNotification('Subscription cancelled successfully', 'success');
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      setCancellationError('Failed to cancel subscription. Please try again.');
      showNotification('Failed to cancel subscription', 'error');
    } finally {
      setIsCancelling(false);
    }
  };

  const handleRemoveCancellation = async () => {
    if (!user || !user.id) return;
    setIsRemovingCancellation(true);
    setCancellationError(null);
  
    try {
      await authFetch(`/api/users/subscription/remove_cancellation?uuid=${user.id}`, {
        method: 'POST',
      });
  
      await refetchSubscription();
      setIsModalOpen(false);
      showNotification('Cancellation removed successfully', 'success');
    } catch (error) {
      console.error('Error removing cancellation:', error);
      setCancellationError('Failed to remove cancellation. Please try again.');
      showNotification('Failed to remove cancellation', 'error');
    } finally {
      setIsRemovingCancellation(false);
    }
  };

  const getSubscriptionStatus = () => {
    const { scheduled_change } = subscription?.subscription || {};
    if (scheduled_change?.action === 'cancel' && scheduled_change?.effective_at) {
      const date = new Date(scheduled_change.effective_at);
      return `Cancelling on ${date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}`;
    }
    return 'Active';
  };

  const handleDowngradeSubscription = async () => {
    setIsDowngrading(true);
    try {
      const currentTier = subscription?.subscription.tier;
      const currentCycle = subscription?.subscription.billing_interval?.interval === 'year' ? 'yearly' : 'monthly';
      const planName = currentTier.charAt(0).toUpperCase() + currentTier.slice(1).toLowerCase();
      
      await new Promise(resolve => setTimeout(resolve, 500));
      
      window.location.href = `/pricing?plan=${planName}&cycle=${currentCycle}&upgradedowngrade=true`;
    } catch (error) {
      setIsDowngrading(false);
      showNotification('Error redirecting to pricing page', 'error');
    }
  };

  if (isLoading) {
    return (
      <PageLayout title="Your Account">
        <div className="flex justify-center items-center min-h-[60vh]">
          <LoadingSpinner size="large" />
        </div>
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout title="Your Account">
        <div className="flex justify-center items-center min-h-[60vh]">
          <div className="text-2xl font-semibold text-red-600">
            Error loading subscription data. Please try again later.
          </div>
        </div>
      </PageLayout>
    );
  }

  if (!user) {
    return (
      <div className="min-h-screen bg-gray-50 flex justify-center items-center">
        <div className="text-2xl font-semibold text-gray-600">Please log in to view your account.</div>
      </div>
    );
  }

  return (
    <PageLayout 
      title="Account" 
      description="Manage your Notifyr account settings and subscription."
      className="max-w-4xl"
      breadcrumbs={[
        { label: 'Dashboard', href: '/' },
        { label: 'Account' }
      ]}
    >
      <div className="space-y-8">
        <SubscriptionCard 
          subscription={subscription} 
          onManageSubscription={() => setIsModalOpen(true)}
          showNotification={showNotification}
          refetchSubscription={refetchSubscription}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <ProfileCard user={user} />
          <DailyEmailUsageCard usage={subscription?.usage} />
        </div>
        <EmailPreferences 
          user={user}
          showNotification={showNotification}
        />
        <DiscordVerification 
          user={user} 
          authFetch={authFetch} 
          showNotification={showNotification} 
        />
      </div>
      
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="text-2xl font-bold mb-4">Manage Your Subscription</h2>
        <p className="mb-4">Your {subscription?.subscription.tier} plan is currently {getSubscriptionStatus()}.</p>
        <div className="flex flex-col space-y-4">
          <button
            className="flex items-center justify-center bg-amber-500 text-white px-4 py-2 rounded hover:bg-amber-600 transition duration-300 disabled:opacity-50"
            onClick={handleDowngradeSubscription}
            disabled={isDowngrading}
          >
            {isDowngrading ? (
              <>
                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                Redirecting...
              </>
            ) : (
              <>
                Downgrade Plan <ArrowDownCircle className="w-4 h-4 ml-2" />
              </>
            )}
          </button>
          <div className="space-y-2">
            <div className="flex items-center text-gray-500 text-sm">
              <span className="mr-1">Cancellation info</span>
              <div className="relative inline-block group">
                <Info className="w-4 h-4 cursor-help" />
                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-800 text-white text-xs rounded-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 w-64 z-10">
                  Your current privileges will remain active until the end of the current billing period. After that, your account will revert to the free tier.
                </div>
              </div>
            </div>
            {subscription?.subscription.scheduled_change?.action === 'cancel' ? (
              <button
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300 w-full disabled:opacity-50 flex items-center justify-center"
                onClick={handleRemoveCancellation}
                disabled={isRemovingCancellation}
              >
                {isRemovingCancellation ? (
                  <>
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                    Removing Cancellation...
                  </>
                ) : (
                  'Remove Scheduled Cancellation'
                )}
              </button>
            ) : (
              <button
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300 w-full disabled:opacity-50 flex items-center justify-center"
                onClick={handleCancelSubscription}
                disabled={isCancelling}
              >
                {isCancelling ? (
                  <>
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                    Cancelling...
                  </>
                ) : (
                  'Cancel Subscription'
                )}
              </button>
            )}
            {cancellationError && (
              <p className="text-red-500 text-sm mt-2">{cancellationError}</p>
            )}
          </div>
        </div>
      </Modal>

      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)}
        />
      )}
    </PageLayout>
  );
};

export default AccountPage;
