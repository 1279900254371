import React from 'react';
import { motion } from 'framer-motion';
import { useIntersectionObserver } from './hooks';
import { useReducedMotion } from './hooks';

const SectionWrapper = ({ children, id }) => {
  const [ref, isVisible] = useIntersectionObserver({
    threshold: 0.1,
    rootMargin: '0px 0px -10% 0px',
  });
  const prefersReducedMotion = useReducedMotion();

  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      id={id}
      ref={ref}
      initial="hidden"
      animate={isVisible ? "visible" : "hidden"}
      variants={variants}
      transition={{ duration: 0.5, ease: "easeOut" }}
      style={{ willChange: 'opacity, transform' }}
    >
      {prefersReducedMotion ? (
        <div>{children}</div>
      ) : (
        children
      )}
    </motion.div>
  );
};

export default SectionWrapper;
