import React from 'react';
import { motion } from 'framer-motion';
import { Users, Bell, Zap, Star } from 'lucide-react';
import { Typewriter } from 'react-simple-typewriter';
import Sonar from './Sonar';

const taglines = [
  "Monitor Reddit in Real-Time",
  "Never Miss a Relevant Post",
  "Stay Ahead of the Conversation"
];

const Hero = () => {
  return (
    <div className="relative bg-gradient-to-b from-slate-50 to-blue-50">
      <div className="h-16 lg:h-20"></div>
      <div className="min-h-[calc(100vh-4rem)] lg:min-h-[calc(100vh-5rem)] flex flex-col">
        <div className="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,transparent,black)] pointer-events-none"></div>
        <div className="flex-grow flex items-center relative overflow-hidden py-12 lg:py-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10 w-full">
            <div className="flex flex-col lg:flex-row items-center lg:items-start space-y-8 lg:space-y-0 lg:space-x-8">
              <div className="w-full lg:w-1/2 lg:pr-8 text-center lg:text-left">
                <div className="inline-block mb-4 px-4 py-2 rounded-full bg-blue-50 text-blue-600 text-sm font-semibold border border-blue-100 shadow-sm glow-blue">
                  <span className="mr-2">🚀</span>
                  <span>New: Real-time Reddit Monitoring</span>
                </div>

                <h1 className="text-3xl sm:text-4xl lg:text-5xl xl:text-6xl font-extrabold tracking-tight mb-6">
                  <div className="h-[2.5em] sm:h-[2em] lg:h-[2.5em] xl:h-[2.5em] flex items-center justify-center lg:justify-start">
                    <div className="text-gray-700">
                      <Typewriter
                        words={taglines}
                        loop={0}
                        cursor
                        cursorStyle='|'
                        typeSpeed={70}
                        deleteSpeed={50}
                        delaySpeed={3000}
                      />
                    </div>
                  </div>
                </h1>
                <p className="mt-4 lg:mt-6 max-w-2xl text-lg lg:text-xl text-gray-700 mb-6 lg:mb-8">
                  Get instant, customizable alerts for your keywords across any subreddit. Stay ahead in fast-moving Reddit communities.
                </p>
                <motion.div 
                  className="flex flex-col sm:flex-row gap-4"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, ease: "easeOut" }}
                >
                  <a
                    href="/"
                    className="w-full sm:w-auto px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-300 ease-in-out transform hover:scale-105 shadow-md text-center"
                  >
                    Start Monitoring
                  </a>
                  <a
                    href="https://discord.gg/vYz3xzz26f"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-full sm:w-auto px-6 py-3 border border-blue-600 text-base font-medium rounded-md text-blue-600 bg-white hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-300 ease-in-out transform hover:scale-105 shadow-md text-center flex items-center justify-center"
                  >
                    <svg className="w-5 h-5 mr-2" viewBox="0 0 71 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0)">
                        <path d="M60.1045 4.8978C55.5792 2.8214 50.7265 1.2916 45.6527 0.41542C45.5603 0.39851 45.468 0.440769 45.4204 0.525289C44.7963 1.6353 44.105 3.0834 43.6209 4.2216C38.1637 3.4046 32.7345 3.4046 27.3892 4.2216C26.905 3.0581 26.1886 1.6353 25.5617 0.525289C25.5141 0.443589 25.4218 0.40133 25.3294 0.41542C20.2584 1.2888 15.4057 2.8186 10.8776 4.8978C10.8384 4.9147 10.8048 4.9429 10.7825 4.9795C1.57795 18.7309 -0.943561 32.1443 0.293408 45.3914C0.299005 45.4562 0.335386 45.5182 0.385761 45.5576C6.45866 50.0174 12.3413 52.7249 18.1147 54.5195C18.2071 54.5477 18.305 54.5139 18.3638 54.4378C19.7295 52.5728 20.9469 50.6063 21.9907 48.5383C22.0523 48.4172 21.9935 48.2735 21.8676 48.2256C19.9366 47.4931 18.0979 46.6 16.3292 45.5858C16.1893 45.5041 16.1781 45.304 16.3068 45.2082C16.679 44.9293 17.0513 44.6391 17.4067 44.3461C17.471 44.2926 17.5606 44.2813 17.6362 44.3151C29.2558 49.6202 41.8354 49.6202 53.3179 44.3151C53.3935 44.2785 53.4831 44.2898 53.5502 44.3433C53.9057 44.6363 54.2779 44.9293 54.6529 45.2082C54.7816 45.304 54.7732 45.5041 54.6333 45.5858C52.8646 46.6197 51.0259 47.4931 49.0921 48.2228C48.9662 48.2707 48.9102 48.4172 48.9718 48.5383C50.038 50.6034 51.2554 52.5699 52.5959 54.435C52.6519 54.5139 52.7526 54.5477 52.845 54.5195C58.6464 52.7249 64.529 50.0174 70.6019 45.5576C70.6551 45.5182 70.6887 45.459 70.6943 45.3942C72.1747 30.0791 68.2147 16.7757 60.1968 4.9823C60.1772 4.9429 60.1437 4.9147 60.1045 4.8978ZM23.7259 37.3253C20.2276 37.3253 17.3451 34.1136 17.3451 30.1693C17.3451 26.225 20.1717 23.0133 23.7259 23.0133C27.308 23.0133 30.1626 26.2532 30.1066 30.1693C30.1066 34.1136 27.28 37.3253 23.7259 37.3253ZM47.3178 37.3253C43.8196 37.3253 40.9371 34.1136 40.9371 30.1693C40.9371 26.225 43.7636 23.0133 47.3178 23.0133C50.9 23.0133 53.7545 26.2532 53.6986 30.1693C53.6986 34.1136 50.9 37.3253 47.3178 37.3253Z" fill="#5865F2"/>
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect width="71" height="55" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                    Join Discord
                  </a>
                </motion.div>
              </div>
              <div className="w-full lg:w-1/2 mt-8 lg:mt-0">
                <div className="bg-white rounded-lg shadow-xl overflow-hidden">
                  <div className="h-64 sm:h-80 lg:h-96">
                    <Sonar />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white/50 backdrop-blur-sm py-6 lg:py-8 border-t border-slate-100">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-2 gap-4 sm:grid-cols-4">
            <Statistic icon={<Users className="w-5 h-5 text-blue-500" />} value="500+" label="Subreddits Monitored" />
              <Statistic icon={<Bell className="w-5 h-5 text-green-500" />} value="10k+" label="Alerts Sent" />
              <Statistic icon={<Zap className="w-5 h-5 text-yellow-500" />} value="<10ms" label="Avg. Processing Time" />
              <Statistic icon={<Star className="w-5 h-5 text-purple-500" />} value="99.9%" label="Uptime" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Statistic = ({ icon, value, label }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="text-center"
  >
    <div className="flex justify-center">{icon}</div>
    <p className="mt-1 text-base sm:text-lg font-bold text-gray-900">{value}</p>
    <p className="mt-0.5 text-xs sm:text-sm text-gray-500">{label}</p>
  </motion.div>
);

export default Hero;
