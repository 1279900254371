import React, { useState, useEffect, memo } from 'react';
import { Clock, Zap, ChevronDown, ExternalLink, Timer } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const extractSubredditFromUrl = (url) => {
  const matches = url.match(/\/r\/([^/]+)/);
  return matches ? matches[1] : 'Unknown';
};

const AlertListItem = memo(({ alert, index }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const keyword = alert.keywords && alert.keywords[0];
  const subredditName = keyword ? keyword.subreddit_name : extractSubredditFromUrl(alert.url);
  const postUrl = `https://www.reddit.com${alert.url}`;
  const keywordNames = alert.keywords.length > 0 
    ? [...new Set(alert.keywords.map(k => k.keyword_name))]
    : [];
  const alertId = alert.alert_id || Math.random().toString(36);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      if (Math.abs(newWidth - windowWidth) > 50) {  
        setWindowWidth(newWidth);
      }
    };
    
    const debouncedHandler = debounce(handleResize, 150);
    window.addEventListener('resize', debouncedHandler);
    return () => window.removeEventListener('resize', debouncedHandler);
  }, [windowWidth]);

  const calculateTimeDelta = (utcDateString) => {
    const now = new Date();
    const alertDate = new Date(utcDateString + 'Z');
    const diffInSeconds = Math.floor((now - alertDate) / 1000);
    
    if (diffInSeconds < 60) return `${diffInSeconds}s`;
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h`;
    return `${Math.floor(diffInSeconds / 86400)}d`;
  };

  const highlightMatches = (text, matches) => {
    if (!text || !matches || matches.length === 0) return text;
    let result = [];
    let lastIndex = 0;
    matches.forEach((match, index) => {
      result.push(text.slice(lastIndex, match.start));
      result.push(
        <span key={index} className="bg-yellow-200 font-bold">
          {text.slice(match.start, match.end + 1)}
        </span>
      );
      lastIndex = match.end + 1;
    });
    result.push(text.slice(lastIndex));
    return result;
  };

  const matches = keyword && keyword.matches ? keyword.matches : [];
  const message = alert.message || 'No message available';
  const previewText = !isExpanded ? message.slice(0, 100) + (message.length > 100 ? '...' : '') : message;

  return (
    <div 
      className={`
        bg-gradient-to-br from-white to-gray-50
        border border-gray-200 rounded-lg shadow-sm hover:shadow-md 
        transition-all duration-200 mb-3 overflow-hidden
        hover:border-blue-200
        ${isExpanded ? 'shadow-md border-blue-200' : ''}
      `}
    >
      <div 
        className="cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="p-3 sm:p-4">
          {/* Header Section */}
          <div className="flex items-center justify-between gap-2 mb-3">
            <div className="flex items-center gap-2 min-w-0">
              <motion.span 
                initial={false}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.1 }}
                className="font-semibold text-sm bg-gradient-to-r from-blue-500 to-blue-600 text-white px-3 py-1 rounded-md shadow-sm whitespace-nowrap flex-shrink-0 max-w-[120px] sm:max-w-[200px] overflow-hidden"
              >
                <span className="block truncate">
                  r/{subredditName}
                </span>
              </motion.span>
              <div className="flex items-center gap-2 min-w-0 overflow-hidden">
                <motion.div 
                  initial={false}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: 0.2 }}
                  className="flex items-center bg-gray-100 text-xs text-gray-600 px-2 py-1 rounded-md whitespace-nowrap flex-shrink-0"
                >
                  <Clock className="w-3 h-3 mr-1 text-blue-500" />
                  {calculateTimeDelta(alert.completion_time)}
                </motion.div>
                <motion.div 
                  initial={false}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: 0.3 }}
                  className="hidden sm:flex items-center bg-green-50 text-xs text-gray-600 px-2 py-1 rounded-md border border-green-100 whitespace-nowrap flex-shrink-0"
                >
                  <Zap className="w-3 h-3 mr-1.5 text-green-500 fill-green-500" />
                  <span className="text-green-700">{alert.time_delta_seconds.toFixed(1)}s</span>
                </motion.div>
                <div className="flex items-center gap-1.5 min-w-0 flex-shrink">
                  {alert.keywords.length > 0 ? (
                    <div className="flex items-center gap-1.5 flex-wrap">
                      {keywordNames.slice(0, windowWidth < 640 ? 1 : 2).map((name, index) => (
                        <span 
                          key={`${alertId}-${name}-${index}`}
                          className={`
                            text-xs px-2 py-0.5 rounded-md font-medium shadow-sm whitespace-nowrap
                            ${name === (keyword?.keyword_name || '') 
                              ? 'bg-blue-100 text-blue-700 border border-blue-200' 
                              : 'bg-gradient-to-br from-gray-50 to-gray-100 border border-gray-200 text-gray-700'}
                          `}
                        >
                          {name}
                        </span>
                      ))}
                      {keywordNames.length > (windowWidth < 640 ? 1 : 2) && (
                        <span className="text-xs text-gray-500 whitespace-nowrap flex-shrink-0 inline-flex items-center bg-gray-50 px-1.5 py-0.5 rounded">
                          +{keywordNames.length - (windowWidth < 640 ? 1 : 2)}
                        </span>
                      )}
                    </div>
                  ) : (
                    <span className="text-xs px-2 py-0.5 rounded-md font-medium shadow-sm whitespace-nowrap bg-red-100 text-red-700 border border-red-200">
                      Deleted Keyword
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div 
              className={`
                p-1.5 rounded-full transition-colors flex-shrink-0
                ${isExpanded 
                  ? 'bg-blue-100 text-blue-600 border border-blue-200' 
                  : 'bg-gray-100 text-gray-500 border border-gray-200 hover:bg-gray-200'
                }
              `}
            >
              <motion.div
                animate={{ rotate: isExpanded ? 180 : 0 }}
                transition={{ duration: 0.2 }}
              >
                <ChevronDown className="w-4 h-4" />
              </motion.div>
            </div>
          </div>

          {/* Content Section */}
          <div className={`
            text-sm text-gray-700
            ${!isExpanded && 'line-clamp-2'}
          `}>
            {highlightMatches(previewText, matches)}
          </div>

          {/* Expanded Content */}
          <AnimatePresence>
            {isExpanded && (
              <motion.div
                initial={{ opacity: 0, height: 0, marginTop: 0 }}
                animate={{ opacity: 1, height: "auto", marginTop: 12 }}
                exit={{ opacity: 0, height: 0, marginTop: 0 }}
                transition={{ duration: 0.2 }}
              >
                <div className="border-t border-gray-100 pt-3">
                  <motion.div
                    initial={{ y: -10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2, delay: 0.1 }}
                  >
                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-3">
                      <div className="flex flex-wrap items-center gap-2">
                        <div className="flex items-center bg-gray-50 text-xs px-2.5 py-1.5 rounded-md border border-gray-200">
                          <Timer className="w-3 h-3 mr-1.5 text-gray-500" />
                          <span className="text-gray-600 font-medium">{alert.processing_time.toFixed(2)}ms</span>
                        </div>
                        <div className="flex sm:hidden items-center bg-green-50 text-xs px-2.5 py-1.5 rounded-md border border-green-100">
                          <Zap className="w-3 h-3 mr-1.5 text-green-500 fill-green-500" />
                          <span className="text-green-700">{alert.time_delta_seconds.toFixed(1)}s</span>
                        </div>
                        <div className="flex items-center bg-blue-50 text-xs px-2.5 py-1.5 rounded-md border border-blue-100">
                          <div className="w-2 h-2 rounded-full bg-blue-400 mr-1.5"></div>
                          <span className="text-blue-700 font-medium capitalize">{alert.status}</span>
                        </div>
                      </div>
                      <a 
                        href={postUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-blue-50 text-blue-600 hover:bg-blue-100 
                                 flex items-center text-sm font-medium px-3.5 py-2 
                                 rounded-md transition-colors border border-blue-200
                                 w-full sm:w-auto justify-center sm:justify-start"
                        onClick={(e) => e.stopPropagation()}
                      >
                        View Post
                        <ExternalLink className="w-3 h-3 ml-1.5" />
                      </a>
                    </div>
                  </motion.div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
});

const ShimmerListItem = memo(() => {
  return (
    <div className="bg-white border border-gray-200 rounded-lg shadow-sm mb-3 overflow-hidden animate-pulse">
      <div className="p-3 sm:p-4">
        <div className="flex items-center justify-between gap-2 mb-3">
          <div className="flex items-center gap-2">
            <div className="w-24 h-6 bg-gray-300 rounded-md"></div>
            <div className="flex items-center gap-2">
              <div className="w-16 h-5 bg-gray-300 rounded-md"></div>
              <div className="hidden sm:block w-20 h-5 bg-gray-300 rounded-md"></div>
            </div>
          </div>
          <div className="w-8 h-8 bg-gray-300 rounded-md"></div>
        </div>
        <div className="space-y-2">
          <div className="w-3/4 h-4 bg-gray-300 rounded"></div>
          <div className="w-2/3 h-4 bg-gray-300 rounded"></div>
        </div>
      </div>
    </div>
  );
});

const AlertList = memo(({ alerts, isLoading }) => {
  if (isLoading && alerts.length === 0) {
    return (
      <div className="w-full space-y-3">
        {[...Array(4)].map((_, index) => (
          <ShimmerListItem key={index} />
        ))}
      </div>
    );
  }

  return (
    <div className="w-full space-y-3">
      {alerts.map((alert) => (
        <AlertListItem key={alert.alert_id || alert.id} alert={alert} />
      ))}
    </div>
  );
});

export default AlertList;