import React from 'react';
import { Medal, ArrowRight, ArrowUpCircle } from 'lucide-react';
import { motion } from 'framer-motion';

const SubscriptionCard = ({ subscription, onManageSubscription, showNotification, refetchSubscription }) => {
  const getTierColors = (tier) => {
    switch (tier) {
      case 'GOLD':
        return {
          bg: 'bg-gradient-to-br from-amber-100 to-amber-200',
          text: 'text-amber-900',
          progressBg: 'bg-amber-300/50',
          progressFill: 'bg-amber-800'
        };
      case 'SILVER':
        return {
          bg: 'bg-gray-100',
          text: 'text-gray-800',
          progressBg: 'bg-gray-300',
          progressFill: 'bg-gray-500'
        };
      case 'BRONZE':
        return {
          bg: 'bg-amber-100',
          text: 'text-amber-800',
          progressBg: 'bg-amber-300',
          progressFill: 'bg-amber-500'
        };
      default:
        return {
          bg: 'bg-amber-100',
          text: 'text-amber-800',
          progressBg: 'bg-amber-300',
          progressFill: 'bg-amber-500'
        };
    }
  };

  const getBillingIntervalDisplay = () => {
    const interval = subscription?.subscription.billing_interval?.interval;
    const frequency = subscription?.subscription.billing_interval?.frequency;
    if (interval === 'month' && frequency === 1) {
      return '/month';
    } else if (interval === 'year' && frequency === 1) {
      return '/year';
    } else {
      return `/${interval}`;
    }
  };

  const getSubscriptionStatus = () => {
    const { scheduled_change } = subscription?.subscription || {};
    if (scheduled_change?.action === 'cancel' && scheduled_change?.effective_at) {
      const date = new Date(scheduled_change.effective_at);
      return `Cancelling on ${date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}`;
    }
    return 'Active';
  };

  const getNextBillingDate = () => {
    const { scheduled_change, next_billed_at } = subscription?.subscription || {};
    
    // If there's a pending cancellation, return N/A
    if (scheduled_change?.action === 'cancel') {
      return 'N/A';
    }
    
    if (next_billed_at) {
      const date = new Date(next_billed_at);
      return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    }
    return 'N/A';
  };

  const handleUpgradeSubscription = () => {
    const currentTier = subscription?.subscription.tier;
    const currentCycle = subscription?.subscription.billing_interval?.interval === 'year' ? 'yearly' : 'monthly';
    const planName = currentTier.charAt(0).toUpperCase() + currentTier.slice(1).toLowerCase();
    window.location.href = `/pricing?plan=${planName}&cycle=${currentCycle}&upgradedowngrade=true`;
  };

  const colors = getTierColors(subscription?.subscription.tier);

  return (
    <motion.div 
      className={`${colors.bg} ${colors.text} shadow hover:shadow-md rounded-lg overflow-hidden p-6`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          <Medal className="w-8 h-8 mr-3" />
          <h2 className="text-2xl font-bold">
            {subscription?.subscription.tier} Plan
          </h2>
        </div>
        <div className="text-right">
          <span className="text-2xl font-bold">
            {subscription?.subscription.price === 0 ? 'Free' : `$${subscription?.subscription.price}${getBillingIntervalDisplay()}`}
          </span>
        </div>
      </div>
      
      <div className="mb-4">
        <p className="text-sm">
          <span className="font-medium">Status:</span> {getSubscriptionStatus()}
        </p>
        <p className="text-sm">
          <span className="font-medium">Next billing:</span> {getNextBillingDate()}
        </p>
      </div>
      
      <p className="text-sm mb-4">
        {subscription?.subscription.tier === 'BRONZE' && subscription?.subscription.price === 0 
          ? 'Basic features available' 
          : 'Enjoy premium features and priority support'}
      </p>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
        <div>
          <p className="text-sm font-medium mb-1">Keywords</p>
          <div className={`${colors.progressBg} rounded-full h-2`}>
            <div 
              className={`${colors.progressFill} rounded-full h-2`}
              style={{ width: `${(subscription?.usage.keyword_count / subscription?.usage.max_keywords) * 100}%` }}
            />
          </div>
          <p className="text-xs mt-1">{subscription?.usage.keyword_count}/{subscription?.usage.max_keywords}</p>
        </div>
        <div>
          <p className="text-sm font-medium mb-1">Subreddits</p>
          <div className={`${colors.progressBg} rounded-full h-2`}>
            <div 
              className={`${colors.progressFill} rounded-full h-2`}
              style={{ width: `${(subscription?.usage.subreddit_count / subscription?.usage.max_subreddits) * 100}%` }}
            />
          </div>
          <p className="text-xs mt-1">{subscription?.usage.subreddit_count}/{subscription?.usage.max_subreddits}</p>
        </div>
      </div>
      
      <div className="flex justify-between items-center">
        {(subscription?.subscription.tier !== 'BRONZE' || subscription?.subscription.price > 0) ? (
          <button 
            className="flex items-center text-current hover:underline text-sm font-medium"
            onClick={onManageSubscription}
          >
            Manage Subscription <ArrowRight className="w-4 h-4 ml-1" />
          </button>
        ) : (
          <div></div>
        )}
        {subscription?.subscription.tier !== 'GOLD' && (
          <button 
            className="flex items-center bg-white text-amber-800 px-3 py-1 rounded-full text-sm font-medium hover:bg-amber-50 transition-colors duration-300"
            onClick={handleUpgradeSubscription}
          >
            Upgrade <ArrowUpCircle className="w-4 h-4 ml-1" />
          </button>
        )}
      </div>
    </motion.div>
  );
};

export default SubscriptionCard;
