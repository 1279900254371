import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(0);

  const faqs = [
    {
      question: "What's included in the free plan?",
      answer: "The free plan includes 1 keyword, 1 subreddit, email alerts, and access to our community support."
    },
    {
      question: "Can I upgrade or downgrade my plan at any time?",
      answer: "Yes, you can upgrade or downgrade your plan at any time. When upgrading, you'll be prorated and billed immediately. When downgrading, your current subscription will continue until the next billing cycle, then you'll be moved to the new plan."
    },
    {
      question: "What payment methods do you accept?",
      answer: "We accept all major credit cards, PayPal, and bank transfers for annual plans."
    },
    {
      question: "How does the billing work when I upgrade or downgrade?",
      answer: "When you upgrade, we'll calculate the prorated amount for the remainder of your current billing cycle and charge you immediately. For downgrades, we'll allow you to continue using your current plan until the end of the billing cycle, then switch you to the new plan."
    },
    {
      question: "Is there a limit to how many alerts I can receive?",
      answer: "There's no limit to the number of alerts you can receive. However, the number of keywords and subreddits you can monitor depends on your plan."
    },
    {
      question: "Can I cancel my subscription at any time?",
      answer: "Yes, you can cancel your subscription at any time. If you cancel, you'll continue to have access to your current plan until the end of your billing cycle."
    },
    {
      question: "Why do you use Discord for notifications?",
      answer: "We use Discord for notifications because it offers several advantages: 1) It allows us to send instant desktop and mobile notifications, which are faster than email. 2) Discord provides a more interactive and community-oriented experience. 3) It's a popular platform among our users, making it convenient for them to receive alerts. 4) Discord's API enables us to send rich, formatted messages with more detailed information about each alert."
    },
    {
      question: "Do I need a Discord account to use your service?",
      answer: "While having a Discord account is highly recommended to get the most out of our service, it's not strictly required. You can still receive email notifications without a Discord account. However, Discord notifications are faster and more feature-rich, so we encourage users to create a free Discord account for the best experience."
    },
  ];

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? -1 : index);
  };

  return (
    <section id="faq" className="bg-white py-32">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h2 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-3xl font-extrabold text-center text-amber-900 mb-8"
        >
          Frequently Asked Questions
        </motion.h2>
        <dl className="space-y-6">
          {faqs.map((faq, index) => (
            <motion.div 
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="border-b border-gray-200 pb-6"
            >
              <dt className="text-lg font-semibold text-amber-900 mb-2">
                <motion.button
                  className="flex justify-between items-center w-full text-left focus:outline-none"
                  onClick={() => toggleFAQ(index)}
                  whileHover={{ scale: 1.01 }}
                  whileTap={{ scale: 0.99 }}
                >
                  {faq.question}
                  <motion.span 
                    className="ml-6 flex-shrink-0"
                    animate={{ rotate: openIndex === index ? 180 : 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <svg className="h-6 w-6 text-amber-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                  </motion.span>
                </motion.button>
              </dt>
              <AnimatePresence>
                {openIndex === index && (
                  <motion.dd
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className="mt-2 text-amber-700 overflow-hidden"
                  >
                    {faq.answer}
                  </motion.dd>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </dl>
      </div>
    </section>
  );
};

export default FAQ;
