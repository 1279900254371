import { useState, useEffect, useRef, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

export const useIntersectionObserver = (options) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  const callback = useMemo(() => {
    return (entries) => {
      const [entry] = entries;
      setIsVisible(entry.isIntersecting);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(callback, options);
    const currentRef = ref.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [ref, options, callback]);

  return [ref, isVisible];
};

export const useReducedMotion = () => {
  const prefersReducedMotion = useMediaQuery(
    { query: '(prefers-reduced-motion: reduce)' },
    undefined,
    (preference) => preference
  );

  return prefersReducedMotion;
};
