import React from 'react';
import Navbar from '../Navbar';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from './Breadcumb';

const PageLayout = ({ children, title, description, breadcrumbs, className = "" }) => {
  const defaultBreadcrumbs = [
    { label: 'Alerts', href: '/alerts' },
    { label: title }
  ];

  return (
    <>
      <Helmet>
        <title>Notifyr - {title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="min-h-screen bg-gray-50">
        <Navbar />
        <main className={`container mx-auto px-4 py-8 ${className}`}>
          <div className="mb-6">
            <Breadcrumb items={breadcrumbs || defaultBreadcrumbs} />
          </div>
          {children}
        </main>
      </div>
    </>
  );
};

export default PageLayout;