import React, { useState, useEffect } from 'react';
import useAuthenticatedApi from '../../../hooks/useAuthenticatedApi';
import LoadingSpinner from '../components/LoadingSpinner';
import { motion } from 'framer-motion';

const EmailPreferences = ({ user, showNotification }) => {
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const { authFetch } = useAuthenticatedApi();

  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        const response = await authFetch(`/api/users/email-preferences?uuid=${user.id}`);
        setIsSubscribed(response.is_subscribed);
      } catch (error) {
        console.error('Error fetching email preferences:', error);
        showNotification('Failed to load email preferences', 'error');
      } finally {
        setIsLoading(false);
      }
    };
    fetchPreferences();
  }, [user.id, authFetch, showNotification]);

  const handleToggleSubscription = async () => {
    if (isUpdating) return;
    
    const previousState = isSubscribed;
    setIsUpdating(true);
    
    // Optimistically update the UI
    setIsSubscribed(!isSubscribed);

    try {
      await authFetch(`/api/users/email-preferences?uuid=${user.id}`, {
        method: 'POST',
        body: JSON.stringify({ is_subscribed: !previousState }),
      });
      
      showNotification(
        `Successfully ${!previousState ? 'subscribed to' : 'unsubscribed from'} email notifications`,
        'success'
      );
    } catch (error) {
      console.error('Error updating email preferences:', error);
      // Revert the optimistic update
      setIsSubscribed(previousState);
      showNotification('Failed to update email preferences', 'error');
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow hover:shadow-md p-6">
      <h2 className="text-xl font-semibold mb-4">Email Preferences</h2>
      <div className="flex items-center justify-between">
        <span>Receive email notifications</span>
        {isLoading ? (
          <div className="w-11 h-6 flex items-center justify-center">
            <LoadingSpinner size="small" />
          </div>
        ) : (
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={isSubscribed}
              onChange={handleToggleSubscription}
              disabled={isUpdating}
            />
            <motion.div
              initial={false}
              animate={{
                backgroundColor: isSubscribed ? '#2563eb' : '#e5e7eb'
              }}
              transition={{ duration: 0.2 }}
              className="relative w-11 h-6 rounded-full"
            >
              <motion.div
                initial={false}
                animate={{
                  x: isSubscribed ? 20 : 2,
                }}
                transition={{ type: "spring", stiffness: 500, damping: 30 }}
                className="absolute top-[2px] w-5 h-5 rounded-full bg-white border border-gray-300 shadow"
              />
              {isUpdating && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <LoadingSpinner size="tiny" className="text-white" />
                </div>
              )}
            </motion.div>
          </label>
        )}
      </div>
      <motion.p 
        className="text-sm text-gray-500 mt-2"
        initial={false}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
      >
        {isLoading 
          ? "Loading preferences..."
          : isSubscribed 
            ? "You will receive email notifications for your alerts." 
            : "You have unsubscribed from email notifications."}
      </motion.p>
    </div>
  );
};

export default EmailPreferences; 