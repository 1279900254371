import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footers';

const BlogPostTemplate = ({ title, date, content }) => {
  const slug = title.toLowerCase().replace(/\s+/g, '-');

  return (
    <>
      <Helmet>
        <title>{title} - Notifyr Blog</title>
        <meta name="description" content={`${title} - Read more on the Notifyr blog.`} />
        <meta property="og:title" content={`${title} - Notifyr Blog`} />
        <meta property="og:description" content={`Read about ${title} on the Notifyr blog.`} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://yourwebsite.com/blog/${slug}`} />
        <link rel="canonical" href={`https://yourwebsite.com/blog/${slug}`} />
      </Helmet>
      <div className="min-h-screen bg-gradient-to-b from-slate-50 to-blue-50">
        <Navbar />
        <div className="relative py-24 sm:py-32">
          <div className="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,transparent,black)] pointer-events-none"></div>
          <div className="max-w-4xl mx-auto px-6 sm:px-6 lg:px-8 relative z-10">
            <Link to="/blog" className="inline-flex items-center text-blue-600 hover:text-blue-800 mb-8 group">
              <svg className="w-5 h-5 mr-2 transform group-hover:-translate-x-1 transition-transform duration-200" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
              </svg>
              Back to Blog
            </Link>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="bg-white/80 backdrop-blur-sm rounded-xl shadow-xl overflow-hidden border border-blue-50"
            >
              <div className="p-8 sm:p-10">
                <div className="mb-8">
                  <motion.div 
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                    className="inline-block mb-6 px-4 py-2 rounded-full bg-blue-50 text-blue-600 text-sm font-semibold border border-blue-100 shadow-sm"
                  >
                    <span className="mr-2">📚</span>
                    <time dateTime={date}>
                      {new Date(date).toLocaleDateString('en-US', { 
                        year: 'numeric', 
                        month: 'long', 
                        day: 'numeric' 
                      })}
                    </time>
                  </motion.div>

                  <h1 className="text-4xl sm:text-5xl font-extrabold text-gray-900 mb-6 leading-tight">
                    {title}
                  </h1>
                </div>

                <motion.div 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                  className="prose prose-lg prose-blue max-w-none"
                >
                  {content}
                </motion.div>
              </div>
            </motion.div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default BlogPostTemplate;
