import React, { createContext, useState, useEffect, useContext } from 'react';
import { initializePaddle } from '@paddle/paddle-js';

const PaddleContext = createContext();

const EXPIRATION_TIME = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

export const PaddleProvider = ({ children }) => {
  const [paddle, setPaddle] = useState(null);
  const [prices, setPrices] = useState({
    silver: { month: null, year: null },
    gold: { month: null, year: null }
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log('PaddleProvider useEffect triggered');
    const getPrices = async (paddleInstance, cycle) => {
      const silverPriceId = cycle === 'month' 
        ? process.env.REACT_APP_SILVER_MONTHLY_PRICE_ID 
        : process.env.REACT_APP_SILVER_YEARLY_PRICE_ID;
      const goldPriceId = cycle === 'month' 
        ? process.env.REACT_APP_GOLD_MONTHLY_PRICE_ID 
        : process.env.REACT_APP_GOLD_YEARLY_PRICE_ID;
      
      try {
        const result = await paddleInstance.PricePreview({ 
          items: [
            { quantity: 1, priceId: silverPriceId },
            { quantity: 1, priceId: goldPriceId }
          ]
        });
        
        setPrices(prevPrices => {
          const newPrices = {
            ...prevPrices,
            silver: { ...prevPrices.silver, [cycle]: result.data.details.lineItems[0].formattedTotals.subtotal },
            gold: { ...prevPrices.gold, [cycle]: result.data.details.lineItems[1].formattedTotals.subtotal }
          };
          cachePrices(newPrices);
          return newPrices;
        });
      } catch (error) {
        console.error("Error fetching prices:", error);
      }
    };
    const fetchPrices = async (paddleInstance) => {
      const cachedPrices = getCachedPrices();
      if (cachedPrices) {
        setPrices(cachedPrices);
      } else {
        await getPrices(paddleInstance, 'month');
        await getPrices(paddleInstance, 'year');
      }
    };

    const initPaddle = async () => {
      setIsLoading(true);
      console.log(process.env.REACT_APP_PADDLE_ENVIRONMENT, process.env.REACT_APP_PADDLE_TOKEN);
      try {
        const paddleInstance = await initializePaddle({
          environment: process.env.REACT_APP_PADDLE_ENVIRONMENT,
          token: process.env.REACT_APP_PADDLE_TOKEN
        });
        if (paddleInstance) {
          console.log(paddleInstance);
          console.log('Paddle initialized successfully');
          setPaddle(paddleInstance);
          await fetchPrices(paddleInstance);
        }
      } catch (error) {
        console.error("Error initializing Paddle:", error);
      } finally {
        setIsLoading(false);
      }
    };
    initPaddle();
  }, []); // Empty dependency array to run only once





  const getCachedPrices = () => {
    const cachedData = localStorage.getItem('paddlePrices');
    if (!cachedData) return null;

    const { prices, timestamp } = JSON.parse(cachedData);
    const now = new Date().getTime();

    if (now - timestamp < EXPIRATION_TIME) {
      return prices;
    }

    return null;
  };

  const cachePrices = (prices) => {
    const data = {
      prices,
      timestamp: new Date().getTime()
    };
    localStorage.setItem('paddlePrices', JSON.stringify(data));
  };

  return (
    <PaddleContext.Provider value={{ paddle, prices, isLoading }}>
      {children}
    </PaddleContext.Provider>
  );
};

export const usePaddle = () => useContext(PaddleContext);
		
