export const pricingPlans = [
  {
    title: "Bronze",
    features: ['3 Keyword', '3 Subreddits', '10 Email Alerts/day', 'Discord Notifications', 'Community Support'],
    icon: "BronzeMedal"
  },
  {
    title: "Silver",
    features: ['15 Keywords', '10 Subreddits', '25 Email Alerts/day', 'Discord Notifications', '24/7 Support'],
    icon: "ShimmeringSilverMedal"
  },
  {
    title: "Gold",
    features: ['25 Keywords', '25 Subreddits', '50 Email Alerts/day', 'Discord Notifications', '24/7 Priority Support'],
    icon: "GoldMedal",
    highlighted: true
  },
  {
    title: "Enterprise",
    features: ['Unlimited Keywords', 'Unlimited Subreddits', 'Custom Integrations', 'Dedicated Account Manager'],
    price: "Custom"
  }
];

export const getPriceId = (title, cycle) => {
  if (title === "Silver") {
    return cycle === 'monthly' 
      ? process.env.REACT_APP_SILVER_MONTHLY_PRICE_ID 
      : process.env.REACT_APP_SILVER_YEARLY_PRICE_ID;
  } else if (title === "Gold") {
    return cycle === 'monthly' 
      ? process.env.REACT_APP_GOLD_MONTHLY_PRICE_ID 
      : process.env.REACT_APP_GOLD_YEARLY_PRICE_ID;
  }
  return null;
};
