import React, { useState } from 'react';
import { ChevronDown, ChevronUp, Hash, Trash2, Edit2, Plus, Clock } from 'lucide-react';
import Modal from '../../../components/Modal';

const KeywordSubredditCard = ({ subreddit, keywords, maxKeywords, onDelete, onEditKeyword, onAddKeyword, onDeleteKeyword }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [keywordToDelete, setKeywordToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const keywordCount = Object.keys(keywords).length;
  const displayedKeywords = isExpanded ? keywords : Object.fromEntries(Object.entries(keywords).slice(0, maxKeywords));

  const Chip = ({ text, color }) => (
    <span className={`
      inline-block px-2 py-1 rounded-full text-xs font-medium mr-1 mb-1
      ${color === 'green' ? 'bg-green-100 text-green-800' : color === 'red' ? 'bg-red-100 text-red-800' : 'bg-blue-100 text-blue-800'}
      shadow-sm
    `}>
      {text}
    </span>
  );
  
  const BatchingChip = ({ batch_time }) => (
    <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-purple-100 text-purple-800 shadow-sm">
      <Clock className="w-3 h-3 mr-1" />
      {batch_time === "0m" ? "Instant" : batch_time}
    </span>
  );

  const NotificationChip = ({ type, enabled }) => (
    <span className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium ${
      enabled 
        ? 'bg-blue-100 text-blue-800' 
        : 'bg-gray-100 text-gray-600'
    }`}>
      {type === 'email' ? (
        <svg 
          className="h-3 w-3 mr-1" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" 
          />
        </svg>
      ) : (
        <svg 
          className="h-3 w-3 mr-1" 
          fill="currentColor" 
          viewBox="0 0 24 24" 
          aria-hidden="true"
        >
          <path d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z" />
        </svg>
      )}
      {type === 'email' ? 'Email' : 'Discord'}
    </span>
  );

  const handleDeleteClick = (keywordId) => {
    setKeywordToDelete(keywordId);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    try {
      await onDeleteKeyword(keywordToDelete);
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error("Error deleting keyword:", error);
      // Handle error (e.g., show error message to user)
    } finally {
      setIsDeleting(false);
      setKeywordToDelete(null);
    }
  };

  return (
    <div className="w-full bg-white shadow-xl rounded-lg overflow-hidden border border-gray-200 transition-all duration-300 hover:shadow-2xl mb-6">
      <div className="bg-gradient-to-r from-purple-200 to-pink-200 p-4">
        <div className="flex justify-between items-center">
          <div className="bg-white text-gray-800 px-3 py-1 rounded-md font-bold shadow">
            /r/{subreddit}
          </div>
          <div className="flex items-center space-x-2">
            <div className="bg-white text-gray-800 text-xs font-semibold px-2 py-1 rounded-md shadow">
              {keywordCount} / {maxKeywords} keywords
            </div>
            <button 
              onClick={() => onDelete(subreddit)} 
              className="text-white hover:text-red-200 transition-colors duration-200"
            >
              <Trash2 className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
      <div className="p-4">
        {keywordCount === 0 ? (
          <div className="flex flex-col items-center justify-center py-8 text-center">
            <p className="text-gray-500 mb-4">No keywords monitored for this subreddit</p>
            <button
              onClick={() => onAddKeyword(subreddit)}
              className="inline-flex items-center px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md shadow-sm transition-colors duration-200"
            >
              <Plus className="w-4 h-4 mr-2" />
              Add Keyword
            </button>
          </div>
        ) : (
          <div>
            {Object.entries(displayedKeywords).map(([keywordId, keywordData]) => (
              <div key={keywordId} className="mb-3 p-3 bg-gray-50 rounded-lg shadow-sm hover:shadow-md border border-blue-200 hover:border-indigo-300 transition-all duration-300">
                <div className="flex justify-between items-center mb-2">
                  <div className="flex items-center">
                    <Hash className="w-4 h-4 mr-2 text-blue-500" />
                    <span className="font-semibold text-gray-700">{keywordData.keyword}</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Chip text={keywordData.enabled ? 'Enabled' : 'Disabled'} color={keywordData.enabled ? 'green' : 'red'} />
                    <BatchingChip batch_time={keywordData.batch_time} />
                    <button 
                      onClick={() => onEditKeyword(subreddit, keywordId)} 
                      className="text-blue-500 hover:text-blue-700 transition-colors duration-200"
                    >
                      <Edit2 className="w-4 h-4" />
                    </button>
                    <button 
                      onClick={() => handleDeleteClick(keywordId)} 
                      className="text-red-500 hover:text-red-700 transition-colors duration-200"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                </div>
                <div className="text-sm text-gray-600">
                  <p className="mb-1">
                    AND: {keywordData.conditions.AND.length > 0 
                      ? keywordData.conditions.AND.map((word, index) => (
                          <Chip key={index} text={word} color="green" />
                        ))
                      : <span className="text-gray-400 italic">None</span>
                    }
                  </p>
                  <p>
                    NOT: {keywordData.conditions.NOT.length > 0
                      ? keywordData.conditions.NOT.map((word, index) => (
                          <Chip key={index} text={word} color="red" />
                        ))
                      : <span className="text-gray-400 italic">None</span>
                    }
                  </p>
                </div>
                <div className="flex items-center mt-2 space-x-2">
                  <NotificationChip type="email" enabled={keywordData.notify_email} />
                  <NotificationChip type="discord" enabled={keywordData.notify_push} />
                </div>
              </div>
            ))}
            {keywordCount > maxKeywords && (
              <button 
                onClick={() => setIsExpanded(!isExpanded)} 
                className="text-blue-500 hover:text-blue-700 flex items-center mt-2 mx-auto"
              >
                {isExpanded ? (
                  <>
                    <ChevronUp className="w-4 h-4 mr-1" />
                    Show Less
                  </>
                ) : (
                  <>
                    <ChevronDown className="w-4 h-4 mr-1" />
                    Show More ({keywordCount - maxKeywords} more)
                  </>
                )}
              </button>
            )}
            {keywordCount < maxKeywords && (
              <button
                onClick={() => onAddKeyword(subreddit)}
                className="mt-4 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-full transition-colors duration-200 flex items-center mx-auto"
              >
                <Plus className="w-4 h-4 mr-2" />
                Add Keyword
              </button>
            )}
          </div>
        )}
      </div>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <h2 className="text-2xl font-bold mb-4">Confirm Deletion</h2>
        <p className="mb-4">Are you sure you want to delete this keyword?</p>
        <div className="flex justify-end space-x-2">
          <button 
            onClick={() => setIsDeleteModalOpen(false)} 
            className="bg-gray-300 hover:bg-gray-400 text-black px-4 py-2 rounded transition duration-300"
          >
            Cancel
          </button>
          <button 
            onClick={handleConfirmDelete} 
            disabled={isDeleting}
            className={`${isDeleting ? 'bg-red-300' : 'bg-red-500 hover:bg-red-600'} text-white px-4 py-2 rounded transition duration-300 flex items-center`}
          >
            {isDeleting ? (
              <>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Deleting...
              </>
            ) : (
              'Confirm Delete'
            )}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default KeywordSubredditCard;