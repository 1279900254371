import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footers';
import Notification from '../../components/Notification';

const ContactPage = () => {
  const [isContact, setIsContact] = useState(true);
  const [formStatus, setFormStatus] = useState({
    isSubmitting: false,
    message: '',
    isError: false
  });
  const [notifications, setNotifications] = useState([]);
  
  // Contact form state
  const [contactForm, setContactForm] = useState({
    name: '',
    email: '',
    message: '',
    contactType: 'bug'
  });

  // Deal form state
  const [dealForm, setDealForm] = useState({
    redditUrl: '',
    productName: '',
    retailPrice: '',
    dealPrice: ''
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const deal = urlParams.get('deal');
    const type = urlParams.get('type');
    if (deal === 'true') setIsContact(false);
    if (type && ['bug', 'feature', 'support', 'account'].includes(type)) {
      setContactForm(prev => ({ ...prev, contactType: type }));
    }
  }, []);

  const validateContactForm = () => {
    const { name, email, message } = contactForm;
    if (!name.trim() || !email.trim() || !message.trim()) {
      return "All fields are required";
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      return "Invalid email format";
    }
    return null;
  };

  const validateDealForm = () => {
    const { redditUrl, productName, retailPrice, dealPrice } = dealForm;
    if (!redditUrl.trim() || !productName.trim() || !retailPrice || !dealPrice) {
      return "All fields are required";
    }
    if (!/^https?:\/\/(www\.)?reddit\.com/.test(redditUrl)) {
      return "Invalid Reddit URL";
    }
    if (isNaN(parseFloat(retailPrice)) || isNaN(parseFloat(dealPrice))) {
      return "Prices must be valid numbers";
    }
    if (parseFloat(dealPrice) >= parseFloat(retailPrice)) {
      return "Deal price must be lower than retail price";
    }
    return null;
  };

  const showNotification = (message, type) => {
    const id = Date.now();
    setNotifications(prev => [...prev, { id, message, type }]);
  };

  const removeNotification = (id) => {
    setNotifications(prev => prev.filter(notification => notification.id !== id));
  };

  useEffect(() => {
    if (notifications.length > 0) {
      const timer = setTimeout(() => {
        setNotifications(prev => prev.slice(1));
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [notifications]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setFormStatus({ isSubmitting: true, message: '', isError: false });

    const validationError = isContact ? validateContactForm() : validateDealForm();
    if (validationError) {
      setFormStatus({ isSubmitting: false, message: '', isError: false });
      showNotification(validationError, 'error');
      return;
    }

    const endpoint = isContact ? '/api/contact' : '/api/submit-deal';
    const formData = isContact ? contactForm : dealForm;

    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });

      if (!response.ok) throw new Error(response.statusText);

      // Reset form on success
      if (isContact) {
        setContactForm({ name: '', email: '', message: '', contactType: 'bug' });
      } else {
        setDealForm({ redditUrl: '', productName: '', retailPrice: '', dealPrice: '' });
      }

      setFormStatus({ isSubmitting: false, message: '', isError: false });
      showNotification('Submission successful!', 'success');

    } catch (error) {
      console.error('Submission error:', error);
      setFormStatus({ isSubmitting: false, message: '', isError: false });
      showNotification('Failed to submit. Please try again.', 'error');
    }
  };

  const handleContactChange = (field) => (e) => {
    setContactForm(prev => ({
      ...prev,
      [field]: e.target.value
    }));
  };

  const handleDealChange = (field) => (e) => {
    setDealForm(prev => ({
      ...prev,
      [field]: e.target.value
    }));
  };

  return (
    <>
      <Helmet>
        <title>Contact Us - Notifyr</title>
        <meta name="description" content="Get in touch with Notifyr. We're here to help with support, feature requests, or any questions you may have about our Reddit monitoring service." />
        <meta name="keywords" content="contact, support, Notifyr, feature request, help, Reddit alerts" />
      </Helmet>
      
      <div className="min-h-screen bg-gradient-to-b from-slate-50 to-blue-50">
        <Navbar />
        <div className="relative py-24 sm:py-32">
          <div className="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,transparent,black)] pointer-events-none"></div>
          <div className="max-w-4xl mx-auto px-6 sm:px-6 lg:px-8 relative z-10">
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-center"
            >
              <motion.div 
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                className="inline-block mb-6 px-4 py-2 rounded-full bg-blue-50 text-blue-600 text-sm font-semibold border border-blue-100 shadow-sm glow-blue"
              >
                <span className="mr-2">💬</span>
                <span>We typically respond within 24 hours</span>
              </motion.div>

              <h2 className="text-3xl sm:text-4xl font-extrabold text-gray-900 mb-4">
                We're Here to Help!
              </h2>
              
              <p className="text-lg sm:text-xl text-gray-600 mb-12 max-w-2xl mx-auto px-4 sm:px-0">
                Whether you need support, want to share a great Reddit deal, or have ideas to make our community even better, we're all ears.
              </p>
            </motion.div>
            
            <div className="flex justify-center mb-8 sm:mb-12 px-4">
              <div className="bg-white/30 backdrop-blur-sm p-1.5 rounded-full shadow-sm border border-blue-100">
                <button
                  className={`px-4 sm:px-6 py-2 sm:py-2.5 rounded-full transition-all duration-300 ${
                    isContact 
                      ? 'bg-blue-600 text-white shadow-sm' 
                      : 'text-gray-600 hover:text-gray-900'
                  }`}
                  onClick={() => setIsContact(true)}
                >
                  Contact Us
                </button>
                <button
                  className={`px-4 sm:px-6 py-2 sm:py-2.5 rounded-full transition-all duration-300 ${
                    !isContact 
                      ? 'bg-blue-600 text-white shadow-sm' 
                      : 'text-gray-600 hover:text-gray-900'
                  }`}
                  onClick={() => setIsContact(false)}
                >
                  Submit Deal
                </button>
              </div>
            </div>

            <div className="flex justify-center px-4 sm:px-0" style={{ minHeight: '520px' }}>
              <AnimatePresence mode="wait">
                <motion.div
                  key={isContact ? 'contact' : 'deal'}
                  initial={{ opacity: 0, x: isContact ? -20 : 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: isContact ? 20 : -20 }}
                  transition={{ duration: 0.3, ease: "easeInOut" }}
                  className="absolute w-full max-w-md"
                >
                  <div className="bg-white/80 backdrop-blur-sm rounded-xl shadow-xl p-6 sm:p-8 border border-blue-50 mx-4 sm:mx-0">
                    <h2 className="text-2xl font-bold mb-6 text-gray-900">
                      {isContact ? 'Get in Touch' : 'Share a Deal'}
                    </h2>
                    
                    <form onSubmit={handleSubmit} className="space-y-4">
                      {isContact ? (
                        <>
                          <InputField
                            type="text"
                            value={contactForm.name}
                            onChange={handleContactChange('name')}
                            placeholder="Your name"
                          />
                          <InputField
                            type="email"
                            value={contactForm.email}
                            onChange={handleContactChange('email')}
                            placeholder="Your email"
                          />
                          <SelectField
                            value={contactForm.contactType}
                            onChange={handleContactChange('contactType')}
                            options={[
                              { value: 'bug', label: 'Bug Report' },
                              { value: 'feature', label: 'Feature Request' },
                              { value: 'support', label: 'General Support' },
                              { value: 'account', label: 'Account Support' },
                            ]}
                          />
                          <TextAreaField
                            value={contactForm.message}
                            onChange={handleContactChange('message')}
                            placeholder="How can we help?"
                          />
                        </>
                      ) : (
                        <>
                          <InputField
                            type="url"
                            value={dealForm.redditUrl}
                            onChange={handleDealChange('redditUrl')}
                            placeholder="Reddit post URL"
                          />
                          <InputField
                            type="text"
                            value={dealForm.productName}
                            onChange={handleDealChange('productName')}
                            placeholder="Product name"
                          />
                          <div className="grid grid-cols-2 gap-4">
                            <InputField
                              type="number"
                              value={dealForm.retailPrice}
                              onChange={handleDealChange('retailPrice')}
                              placeholder="Retail price"
                            />
                            <InputField
                              type="number"
                              value={dealForm.dealPrice}
                              onChange={handleDealChange('dealPrice')}
                              placeholder="Deal price"
                            />
                          </div>
                        </>
                      )}
                      
                      <button
                        type="submit"
                        disabled={formStatus.isSubmitting}
                        className={`w-full font-medium py-3 px-6 rounded-lg transition-all duration-300 ${
                          formStatus.isSubmitting
                            ? 'bg-blue-400 cursor-not-allowed'
                            : 'bg-blue-600 hover:bg-blue-700 hover:shadow-lg transform hover:-translate-y-0.5'
                        } text-white flex items-center justify-center space-x-2`}
                      >
                        {formStatus.isSubmitting ? (
                          <>
                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            <span>Processing...</span>
                          </>
                        ) : (
                          <>
                            <span>{isContact ? 'Send Message' : 'Submit Deal'}</span>
                            <svg 
                              className="w-5 h-5 ml-2 transition-transform duration-300 transform group-hover:translate-x-1" 
                              fill="none" 
                              stroke="currentColor" 
                              viewBox="0 0 24 24"
                            >
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                            </svg>
                          </>
                        )}
                      </button>
                    </form>
                  </div>
                </motion.div>
              </AnimatePresence>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <AnimatePresence>
        {notifications.map(({ id, message, type }) => (
          <Notification
            key={id}
            message={message}
            type={type}
            duration={5000}
            onClose={() => removeNotification(id)}
          />
        ))}
      </AnimatePresence>
    </>
  );
};

const InputField = ({ type, value, onChange, placeholder }) => (
  <div className="relative mb-4">
    <input
      type={type}
      className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white/90 backdrop-blur-sm transition-all duration-300"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      required
    />
  </div>
);

const SelectField = ({ value, onChange, options }) => (
  <div className="relative mb-4">
    <select
      className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white/90 backdrop-blur-sm appearance-none transition-all duration-300"
      value={value}
      onChange={onChange}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
      <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
      </svg>
    </div>
  </div>
);

const TextAreaField = ({ value, onChange, placeholder }) => (
  <div className="relative mb-4">
    <textarea
      className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white/90 backdrop-blur-sm transition-all duration-300"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      rows="4"
      required
    />
  </div>
);

export default ContactPage;
