import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../../AuthContext';
import { Plus, LayoutGrid, List } from 'lucide-react';
import KeywordSubredditCard from './KeywordSubredditCard';
import Modal from '../../../components/Modal';
import { minutesToUIFormat, uiFormatToMinutes } from './utils';
import Notification from './Notification';
import { AnimatePresence } from 'framer-motion';
import ShimmerCard from './ShimmerCard';
import KeywordModal from './KeywordModal';
import useAuthenticatedApi from '../../../hooks/useAuthenticatedApi';
import PageLayout from '../../../components/PageLayout';
import KeywordList from './KeywordList';
import { motion } from 'framer-motion';

const KeywordPage = () => {
  const { user, loading: authLoading } = useAuth();
  const { authFetch, useApiRequest } = useAuthenticatedApi();
  
  const [subreddits, setSubreddits] = useState({});
  const [isAddSubredditModalOpen, setIsAddSubredditModalOpen] = useState(false);
  const [isDeleteSubredditModalOpen, setIsDeleteSubredditModalOpen] = useState(false);
  const [subredditToDelete, setSubredditToDelete] = useState(null);
  const [subredditForKeywordEdit, setSubredditForKeywordEdit] = useState(null);
  const [isAddingSubreddit, setIsAddingSubreddit] = useState(false);
  const [isDeletingSubreddit, setIsDeletingSubreddit] = useState(false);
  const [editingKeyword, setEditingKeyword] = useState(null);
  const [isAddKeywordModalOpen, setIsAddKeywordModalOpen] = useState(false);
  const [subredditForKeywordAdd, setSubredditForKeywordAdd] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [maxKeywords, setMaxKeywords] = useState(0);
  const [maxSubreddits, setMaxSubreddits] = useState(0);
  const [viewMode, setViewMode] = useState(() => 
    localStorage.getItem('keywordViewMode') || 'grid'
  );

  useEffect(() => {
    localStorage.setItem('keywordViewMode', viewMode);
  }, [viewMode]);

  const fetchUserData = useCallback(async (userId) => {
    const data = await authFetch(`/api/users/data?uuid=${userId}`);
    setMaxKeywords(data.subscription.max_keywords);
    setMaxSubreddits(data.subscription.max_subreddits);
    const formattedData = Object.entries(data.subreddits).reduce((acc, [subreddit, keywords]) => {
      acc[subreddit] = Object.entries(keywords).reduce((keywordAcc, [keywordId, keyword]) => {
        keywordAcc[keywordId] = {
          ...keyword,
          batch_time: minutesToUIFormat(keyword.batch_time)
        };
        return keywordAcc;
      }, {});
      return acc;
    }, {});
    return formattedData;
  }, [authFetch]);

  const { data: userData, isLoading, error } = useApiRequest(fetchUserData);

  useEffect(() => {
    if (userData) {
      console.log('Setting subreddits with userData:', userData);
      setSubreddits(userData);
    }
  }, [userData]);

  const usedSubreddits = Object.keys(subreddits).length;

  const showNotification = (message, type) => {
    const id = Date.now();
    setNotifications(prev => [...prev, { id, message, type }]);
  };

  useEffect(() => {
    if (notifications.length > 0) {
      const timer = setTimeout(() => {
        setNotifications(prev => prev.slice(1));
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [notifications]);

  const removeNotification = (id) => {
    setNotifications(prev => prev.filter(notification => notification.id !== id));
  };

  const handleDeleteSubreddit = (subredditName) => {
    setSubredditToDelete(subredditName);
    setIsDeleteSubredditModalOpen(true);
  };

  const confirmDeleteSubreddit = async () => {
    setIsDeletingSubreddit(true);
    try {
      await authFetch(`/api/subreddits/${subredditToDelete}`, {
        method: 'DELETE',
        body: JSON.stringify({
          user_id: user.id,
        }),
      });

      setSubreddits(prevSubreddits => {
        const newSubreddits = { ...prevSubreddits };
        delete newSubreddits[subredditToDelete];
        return newSubreddits;
      });
      setIsDeleteSubredditModalOpen(false);
      setSubredditToDelete(null);
      showNotification('Subreddit deleted successfully', 'success');
    } catch (error) {
      console.error('Error removing subreddit:', error);
      showNotification(`Error removing subreddit: ${error.message}`, 'error');
    } finally {
      setIsDeletingSubreddit(false);
    }
  };

  const handleAddSubreddit = () => {
    setIsAddSubredditModalOpen(true);
  };

  const confirmAddSubreddit = async (newSubredditName) => {
    setIsAddingSubreddit(true);
    try {
      const response = await authFetch('/api/subreddits/', {
        method: 'POST',
        body: JSON.stringify({
          subreddit_name: newSubredditName,
          user_id: user.id,
        }),
      });

      if (response.status === 'success') {
        setSubreddits(prevSubreddits => ({
          ...prevSubreddits,
          [newSubredditName.toLowerCase()]: {}
        }));
        setIsAddSubredditModalOpen(false);
        showNotification(response.message || 'Subreddit added successfully', 'success');
      }
    } catch (error) {
      console.error('Error adding subreddit:', error);
      showNotification(error.message || 'Error adding subreddit', 'error');
    } finally {
      setIsAddingSubreddit(false);
    }
  };

  const handleEditKeyword = (subreddit, keywordId) => {
    const keywordToEdit = {...subreddits[subreddit][keywordId], id: keywordId};
    setEditingKeyword(keywordToEdit);
    setSubredditForKeywordEdit(subreddit);
  };

  const confirmEditKeyword = async (updatedKeyword) => {
    try {
      await authFetch('/api/keywords/update', {
        method: 'POST',
        body: JSON.stringify({
          subreddit: subredditForKeywordEdit,
          user_id: user.id,
          keyword_id: updatedKeyword.id,
          keywords: {
            AND: updatedKeyword.conditions.AND,
            NOT: updatedKeyword.conditions.NOT,
          },
          batch_time: uiFormatToMinutes(updatedKeyword.batch_time),
          enabled: updatedKeyword.enabled,
          notify_push: updatedKeyword.notify_push,
          notify_email: updatedKeyword.notify_email
        }),
      });

      setSubreddits(prevSubreddits => {
        const newSubreddits = {...prevSubreddits};
        newSubreddits[subredditForKeywordEdit] = {
          ...newSubreddits[subredditForKeywordEdit],
          [updatedKeyword.id]: {
            keyword: updatedKeyword.keyword,
            conditions: updatedKeyword.conditions,
            enabled: updatedKeyword.enabled,
            batch_time: (updatedKeyword.batch_time),
            notify_push: updatedKeyword.notify_push,
            notify_email: updatedKeyword.notify_email
          }
        };
        return newSubreddits;
      });
      setEditingKeyword(null);
      setSubredditForKeywordEdit(null);
      showNotification('Keyword updated successfully', 'success');
    } catch (error) {
      console.error('Error updating keyword:', error);
      showNotification(`Error updating keyword: ${error.message}`, 'error');
    }
  };

  const handleAddKeyword = (subreddit) => {
    setSubredditForKeywordAdd(subreddit);
    setIsAddKeywordModalOpen(true);
  };

  const confirmAddKeyword = async (newKeyword) => {
    try {
      const data = await authFetch('/api/keywords/add', {
        method: 'POST',
        body: JSON.stringify({
          subreddit: subredditForKeywordAdd,
          user_id: user.id,
          keyword_id: newKeyword.keyword,
          keywords: {
            AND: newKeyword.conditions.AND,
            NOT: newKeyword.conditions.NOT,
          },
          batch_time: uiFormatToMinutes(newKeyword.batch_time),
        }),
      });

      setSubreddits(prevSubreddits => ({
        ...prevSubreddits,
        [subredditForKeywordAdd]: {
          ...prevSubreddits[subredditForKeywordAdd],
          [data.id]: {
            ...newKeyword,
            batch_time: newKeyword.batch_time || "0m",
            notify_email: newKeyword.notify_email,
            notify_push: newKeyword.notify_push
          }
        }
      }));
      setIsAddKeywordModalOpen(false);
      setSubredditForKeywordAdd(null);
      showNotification('Keyword added successfully', 'success');
    } catch (error) {
      console.error('Error adding keyword:', error);
      showNotification(`Error adding keyword: ${error.message}`, 'error');
    }
  };

  const handleDeleteKeyword = async (subreddit, keywordId) => {
    try {
      await authFetch('/api/keywords/remove', {
        method: 'POST',
        body: JSON.stringify({
          subreddit: subreddit,
          user_id: user.id,
          keyword_id: keywordId,
        }),
      });

      setSubreddits(prevSubreddits => {
        const newSubreddit = { ...prevSubreddits[subreddit] };
        delete newSubreddit[keywordId];
        return {
          ...prevSubreddits,
          [subreddit]: newSubreddit
        };
      });
      showNotification('Keyword deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting keyword:', error);
      showNotification(`Error deleting keyword: ${error.message}`, 'error');
    }
  };

  const renderContent = () => {
    console.log('renderContent called with:', {
      error,
      authLoading,
      isLoading,
      subreddits
    });

    if (error) {
      return (
        <div className="text-center py-8">
          <p className="text-red-600 mb-4">Error loading keywords: {error.message}</p>
          <button
            onClick={() => window.location.reload()}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Retry
          </button>
        </div>
      );
    }

    if (authLoading || isLoading) {
      return (
        <div className={viewMode === 'list' ? '' : 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'}>
          {[1, 2, 3].map((n) => (
            viewMode === 'list' ? (
              <div key={n} className="bg-white rounded-lg shadow-sm border border-gray-200 p-4 mb-4 animate-pulse">
                <div className="h-6 bg-gray-200 rounded w-1/4 mb-4"></div>
                <div className="space-y-3">
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                  <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                </div>
              </div>
            ) : (
              <ShimmerCard key={n} />
            )
          ))}
        </div>
      );
    }

    if (Object.keys(subreddits).length === 0) {
      return (
        <div className="text-center py-8">
          <p className="text-gray-600 mb-4">No subreddits found. Add one to get started!</p>
          <button
            onClick={() => setIsAddSubredditModalOpen(true)}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center mx-auto"
          >
            <Plus className="w-5 h-5 mr-2" />
            Add Subreddit
          </button>
        </div>
      );
    }

    return (
      <motion.div
        key={viewMode}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ 
          duration: 0.15,
          ease: "easeOut"
        }}
        className="w-full"
      >
        {viewMode === 'grid' ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {Object.entries(subreddits).map(([subreddit, keywords]) => (
              <KeywordSubredditCard
                key={subreddit}
                subreddit={subreddit}
                keywords={keywords}
                onEditKeyword={handleEditKeyword}
                onDeleteKeyword={handleDeleteKeyword}
                onAddKeyword={handleAddKeyword}
                maxKeywords={maxKeywords}
                onDelete={() => handleDeleteSubreddit(subreddit)}
              />
            ))}
          </div>
        ) : (
          <KeywordList
            subreddits={subreddits}
            onEditKeyword={handleEditKeyword}
            onDeleteKeyword={handleDeleteKeyword}
            onAddKeyword={handleAddKeyword}
            maxKeywords={maxKeywords}
            onDelete={handleDeleteSubreddit}
          />
        )}
      </motion.div>
    );
  };

  return (
    <PageLayout 
      title="Keywords" 
      description="Manage your Reddit keyword alerts and subreddit subscriptions with Notifyr."
      className="max-w-7xl"
      breadcrumbs={[
        { label: 'Dashboard', href: '/' },
        { label: 'Keywords' }
      ]}
    >
      <div className="bg-white shadow-md rounded-lg p-4 border border-gray-200 mb-6">
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-2">
            <button
              onClick={handleAddSubreddit}
              className={`bg-blue-600 text-white hover:bg-blue-700 
                       flex items-center text-sm font-medium px-3 py-2
                       rounded-md transition-colors shadow-sm
                       whitespace-nowrap relative ${
                         usedSubreddits >= maxSubreddits ? 'opacity-50 cursor-not-allowed' : ''
                       }`}
              disabled={usedSubreddits >= maxSubreddits}
            >
              <Plus className="w-4 h-4 mr-1.5" />
              Add Subreddit
              <div className="sm:hidden ml-2 bg-white/20 text-white text-xs px-1.5 py-0.5 rounded-md">
                {usedSubreddits}/{maxSubreddits}
              </div>
            </button>
            <div className="hidden sm:block bg-gray-100 text-gray-600 px-3 py-1 rounded-md text-sm font-medium whitespace-nowrap border border-gray-200">
              {usedSubreddits} / {maxSubreddits} subreddits
            </div>
          </div>
          <div className="bg-white rounded-lg p-1 flex items-center border border-gray-200 w-24">
            <button
              onClick={() => setViewMode('grid')}
              className={`flex-1 p-1.5 rounded-md transition-all duration-200 ${
                viewMode === 'grid' 
                  ? 'bg-blue-100 text-blue-600' 
                  : 'text-gray-400 hover:text-gray-600 hover:bg-gray-50'
              }`}
              title="Grid View"
            >
              <LayoutGrid className="w-4 h-4 mx-auto" />
            </button>
            <button
              onClick={() => setViewMode('list')}
              className={`flex-1 p-1.5 rounded-md transition-all duration-200 ${
                viewMode === 'list' 
                  ? 'bg-blue-100 text-blue-600' 
                  : 'text-gray-400 hover:text-gray-600 hover:bg-gray-50'
              }`}
              title="List View"
            >
              <List className="w-4 h-4 mx-auto" />
            </button>
          </div>
        </div>
      </div>

      {renderContent()}

      <Modal isOpen={isAddSubredditModalOpen} onClose={() => setIsAddSubredditModalOpen(false)}>
        <h2 className="text-2xl font-bold mb-4">Add New Subreddit</h2>
        <input
          type="text"
          placeholder="Enter subreddit name"
          className="w-full p-2 border rounded mb-4"
          id="newSubredditName"
        />
        <button 
          onClick={() => confirmAddSubreddit(document.getElementById('newSubredditName').value)}
          disabled={isAddingSubreddit}
          className={`w-full ${isAddingSubreddit ? 'bg-blue-300' : 'bg-blue-500 hover:bg-blue-600'} text-white px-4 py-2 rounded transition duration-300 flex items-center justify-center`}
        >
          {isAddingSubreddit ? (
            <>
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Adding...
            </>
          ) : (
            'Add Subreddit'
          )}
        </button>
      </Modal>

      <Modal isOpen={isDeleteSubredditModalOpen} onClose={() => setIsDeleteSubredditModalOpen(false)}>
        <h2 className="text-2xl font-bold mb-4">Confirm Deletion</h2>
        <p className="mb-4">Are you sure you want to delete /r/{subredditToDelete}?</p>
        <div className="flex justify-end space-x-2">
          <button 
            onClick={() => setIsDeleteSubredditModalOpen(false)} 
            className="bg-gray-300 hover:bg-gray-400 text-black px-4 py-2 rounded transition duration-300"
          >
            Cancel
          </button>
          <button 
            onClick={confirmDeleteSubreddit}
            disabled={isDeletingSubreddit}
            className={`${isDeletingSubreddit ? 'bg-red-300' : 'bg-red-500 hover:bg-red-600'} text-white px-4 py-2 rounded transition duration-300 flex items-center`}
          >
            {isDeletingSubreddit ? (
              <>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Deleting...
              </>
            ) : (
              'Confirm Delete'
            )}
          </button>
        </div>
      </Modal>

      <KeywordModal
        isOpen={!!editingKeyword}
        onClose={() => setEditingKeyword(null)}
        keywordToEdit={editingKeyword}
        confirmAction={confirmEditKeyword}
        mode="edit"
      />

      <KeywordModal
        isOpen={isAddKeywordModalOpen}
        onClose={() => setIsAddKeywordModalOpen(false)}
        confirmAction={confirmAddKeyword}
        mode="add"
      />

      <AnimatePresence>
        {notifications.map(({ id, message, type }) => (
          <Notification
            key={id}
            message={message}
            type={type}
            duration={5000}
            onClose={() => removeNotification(id)}
          />
        ))}
      </AnimatePresence>
    </PageLayout>
  );
};

export default KeywordPage;