import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footers';
import { motion } from 'framer-motion';

const blogPosts = [
  {
    slug: 'how-to-get-instant-alerts',
    title: 'How to Get Instant Alerts for Reddit Deals: A Step-by-Step Guide',
    date: '2023-05-01',
    excerpt: 'Learn how to set up a foolproof system for catching the best Reddit deals as soon as they\'re posted.'
  },
  {
    slug: 'mastering-keyword-alerts',
    title: 'Mastering Reddit Keyword Alerts: Advanced Techniques for Deal Hunters',
    date: '2023-05-25',
    excerpt: 'Dive deep into advanced techniques that will elevate your keyword game and help you catch even the most elusive deals.'
  },
  // Add more blog posts here as needed
];

const BlogPage = () => {
  return (
    <>
      <Helmet>
        <title>Notifyr Blog - Reddit Alert Tips and Insights</title>
        <meta name="description" content="Stay updated with the latest tips, tricks, and insights about Reddit alerts and deal-finding strategies from the Notifyr team." />
        <meta property="og:title" content="Notifyr Blog - Reddit Alert Tips and Insights" />
        <meta property="og:description" content="Stay updated with the latest tips, tricks, and insights about Reddit alerts and deal-finding strategies from the Notifyr team." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://yourwebsite.com/blog" />
        <link rel="canonical" href="https://yourwebsite.com/blog" />
      </Helmet>
      
      <div className="min-h-screen bg-gradient-to-b from-slate-50 to-blue-50">
        <Navbar />
        <div className="relative py-24 sm:py-32">
          <div className="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,transparent,black)] pointer-events-none"></div>
          <div className="max-w-7xl mx-auto px-6 sm:px-6 lg:px-8 relative z-10">
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-center mb-16"
            >
              <motion.div 
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                className="inline-block mb-6 px-4 py-2 rounded-full bg-blue-50 text-blue-600 text-sm font-semibold border border-blue-100 shadow-sm glow-blue"
              >
                <span className="mr-2">📚</span>
                <span>Tips & Insights from the Notifyr Team</span>
              </motion.div>

              <h1 className="text-4xl sm:text-5xl font-extrabold text-gray-900 mb-4">
                Notifyr Blog
              </h1>
              
              <p className="text-lg sm:text-xl text-gray-600 mb-12 max-w-2xl mx-auto">
                Discover the latest strategies, tips, and insights about Reddit alerts and deal-finding techniques.
              </p>
            </motion.div>

            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
              {blogPosts.map((post, index) => (
                <motion.article
                  key={post.slug}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="group relative bg-white/80 backdrop-blur-sm rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-all duration-300 border border-blue-50"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-50/50 to-purple-50/50 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  
                  <Link to={`/blog/${post.slug}`} className="block h-full relative z-10">
                    <div className="p-8 flex flex-col h-full">
                      <div className="mb-4">
                        <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-50 text-blue-600 border border-blue-100">
                          <svg className="w-4 h-4 mr-1.5" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                          </svg>
                          Featured
                        </span>
                      </div>

                      <h2 className="text-2xl font-bold text-gray-900 mb-4 group-hover:text-blue-600 transition-colors duration-200">
                        {post.title}
                      </h2>

                      <p className="text-gray-600 leading-relaxed mb-6 flex-grow">
                        {post.excerpt}
                      </p>

                      <div className="pt-6 border-t border-gray-100">
                        <div className="flex items-center justify-between">
                          <time dateTime={post.date} className="flex items-center text-sm text-gray-500">
                            <svg className="w-4 h-4 mr-2 text-blue-400" fill="currentColor" viewBox="0 0 20 20">
                              <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                            </svg>
                            {new Date(post.date).toLocaleDateString('en-US', { 
                              year: 'numeric', 
                              month: 'long', 
                              day: 'numeric' 
                            })}
                          </time>

                          <span className="inline-flex items-center text-sm font-medium text-blue-600 group-hover:text-blue-500">
                            Read More
                            <svg className="w-4 h-4 ml-1 transform group-hover:translate-x-1 transition-transform duration-200" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </motion.article>
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default BlogPage;
