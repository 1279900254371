import React, { useState, useRef, useEffect } from 'react';
import { Clock, Zap, ChevronDown, ChevronUp, ExternalLink } from 'lucide-react';

const extractSubredditFromUrl = (url) => {
  const matches = url.match(/\/r\/([^/]+)/);
  return matches ? matches[1] : 'Unknown';
};

const calculateGradientIndexFromSubreddit = (subredditName) => {
  const asciiSum = (subredditName + 'x')
    .split('')
    .reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return asciiSum % gradientCombos.length;
};

const gradientCombos = [
  { start: "from-purple-200", end: "to-pink-200" },
  { start: "from-blue-200", end: "to-indigo-200" },
  { start: "from-green-200", end: "to-teal-200" },
  { start: "from-red-200", end: "to-orange-200" },
  { start: "from-indigo-200", end: "to-purple-200" },
  { start: "from-pink-200", end: "to-rose-200" },
  { start: "from-cyan-200", end: "to-blue-200" },
  { start: "from-fuchsia-200", end: "to-pink-200" },
  { start: "from-amber-200", end: "to-orange-200" },
  { start: "from-lime-200", end: "to-emerald-200" },
];

const AlertCard = ({ alert }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showExpandButton, setShowExpandButton] = useState(false);
  const contentRef = useRef(null);
  const textRef = useRef(null);
  
  const preserveWhitespaceClass = "whitespace-pre-wrap";

  const keyword = alert.keywords && alert.keywords[0];
  const subredditName = keyword ? keyword.subreddit_name : extractSubredditFromUrl(alert.url);
  const postUrl = `https://www.reddit.com${alert.url}`;

  const gradientIndex = calculateGradientIndexFromSubreddit(subredditName);
  const gradient = gradientCombos[gradientIndex];

  const calculateTimeDelta = (utcDateString) => {
    const now = new Date();
    const alertDate = new Date(utcDateString + 'Z');
    const diffInSeconds = Math.floor((now - alertDate) / 1000);
    
    if (diffInSeconds < 60) return `${diffInSeconds} seconds ago`;
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    return `${Math.floor(diffInSeconds / 86400)} days ago`;
  };

  const highlightMatches = (text, matches) => {
    if (!text || !matches || matches.length === 0) return text;
    let result = [];
    let lastIndex = 0;
    matches.forEach((match, index) => {
      result.push(text.slice(lastIndex, match.start));
      result.push(
        <span key={index} className="bg-yellow-200 font-bold">
          {text.slice(match.start, match.end + 1)}
        </span>
      );
      lastIndex = match.end + 1;
    });
    result.push(text.slice(lastIndex));
    return result;
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  const message = alert.message || 'No message available';
  const matches = keyword && keyword.matches ? keyword.matches : [];

  const keywordNames = alert.keywords.length > 0 
    ? [...new Set(alert.keywords.map(k => k.keyword_name))]
    : [];

  useEffect(() => {
    const checkTextOverflow = () => {
      if (contentRef.current && textRef.current) {
        const contentHeight = contentRef.current.clientHeight;
        const textHeight = textRef.current.scrollHeight;
        setShowExpandButton(textHeight > contentHeight);
      }
    };

    checkTextOverflow();
    window.addEventListener('resize', checkTextOverflow);
    return () => window.removeEventListener('resize', checkTextOverflow);
  }, [message]);

  const handleToggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div className="w-full bg-white shadow-xl rounded-lg overflow-hidden border border-gray-200 transition-all duration-300 hover:shadow-2xl mb-6 flex flex-col h-[300px]">
      <div className={`bg-gradient-to-r ${gradient.start} ${gradient.end} p-3 text-white flex-shrink-0`}>
        <div className="flex justify-between items-center">
          <div className="bg-white text-gray-800 px-2 py-1 text-sm font-bold rounded-md shadow max-w-[150px] sm:max-w-[200px]">
            <span className="block truncate">
              /r/{truncateText(subredditName, 25)}
            </span>
          </div>
          <div className="bg-white text-gray-800 text-xs font-semibold px-2 py-1 rounded-md shadow ml-2 flex-shrink-0">
            ID: {alert.alert_id}
          </div>
        </div>
      </div>
      <div ref={contentRef} className="p-3 flex-grow overflow-hidden flex flex-col relative">
        <div 
          ref={textRef} 
          className={`text-gray-800 text-sm ${preserveWhitespaceClass} ${isExpanded ? 'overflow-y-auto' : 'overflow-hidden'} flex-grow`}
          style={{ maxHeight: isExpanded ? 'calc(100% - 28px)' : '100%' }}
        >
          {highlightMatches(message, matches)}
        </div>
        {showExpandButton && (
          <button 
            onClick={handleToggleExpand} 
            className="text-blue-500 hover:text-blue-700 flex items-center self-start text-xs bg-white py-1 mt-2 absolute bottom-0 left-3"
          >
            {isExpanded ? (
              <>
                <ChevronUp className="w-3 h-3 mr-1" />
                Show Less
              </>
            ) : (
              <>
                <ChevronDown className="w-3 h-3 mr-1" />
                Show More
              </>
            )}
          </button>
        )}
      </div>
      <div className="p-3 flex-shrink-0 border-t border-gray-200 bg-gray-50">
        <div className="flex justify-between items-center text-xs text-gray-600 mb-2">
          <div className="flex items-center">
            <Clock className="w-3 h-3 mr-1 text-blue-500" />
            <span>{calculateTimeDelta(alert.completion_time)}</span>
          </div>
          <div className="flex items-center">
            <Zap className="w-3 h-3 mr-1 text-green-500" />
            <span>Detected in {alert.time_delta_seconds.toFixed(2)}s</span>
          </div>
        </div>
        <div className="flex justify-between items-center text-xs">
          <a 
            href={postUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:text-blue-700 flex items-center"
          >
            View Post
            <ExternalLink className="w-3 h-3 ml-1" />
          </a>
          <div className="flex flex-wrap justify-end">
            {keywordNames.length > 0 ? (
              keywordNames.map((name, index) => (
                <span key={index} className="bg-blue-100 text-blue-800 text-xs font-semibold mr-1 px-2 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 mb-1">
                  {name}
                </span>
              ))
            ) : (
              <span className="bg-red-100 text-red-800 text-xs font-semibold mr-1 px-2 py-0.5 rounded border border-red-200 mb-1">
                Deleted Keyword
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertCard;
