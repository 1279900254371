import React, { createContext, useContext, useEffect, useState, useMemo, useCallback, useRef } from 'react';
import supabase from './supabase/supabaseClient';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [session, setSession] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Use ref to track current session without causing dependency cycles
    const sessionRef = useRef(null);

    const updateSession = useCallback((newSession, force = false) => {
        // Only update if session actually changed or if force=true
        const sessionChanged = 
            (newSession?.user?.id !== sessionRef.current?.user?.id) || 
            (newSession === null && sessionRef.current !== null) ||
            force;
            
        if (sessionChanged) {
            console.log('Updating session state:', newSession?.user?.id);
            sessionRef.current = newSession;
            setSession(newSession);
            setUser(newSession?.user ?? null);
        }
    }, []); // No dependencies needed now

    useEffect(() => {
        let mounted = true;
        let initialSessionProcessed = false;

        async function setupAuth() {
            // Initial session check
            const { data: initialData, error: initialError } = await supabase.auth.getSession();
            if (!mounted) return;

            if (initialError) {
                console.error('Error fetching initial session:', initialError);
                setError(initialError.message);
            } else if (initialData.session) {
                console.log('Initial session found');
                updateSession(initialData.session, true);
                initialSessionProcessed = true;
            }
            setLoading(false);

            // Auth state change listener
            const { data: listener } = supabase.auth.onAuthStateChange(async (event, session) => {
                console.log('Auth state changed:', event);
                if (!mounted) return;

                // Only process auth changes after initial session is handled
                if (event === 'SIGNED_IN' && !initialSessionProcessed) {
                    updateSession(session, true);
                    initialSessionProcessed = true;
                } else if (event !== 'INITIAL_SESSION') {
                    updateSession(session);
                }
            });

            return listener;
        }

        let listener;
        setupAuth().then(l => listener = l);

        return () => {
            mounted = false;
            listener?.subscription.unsubscribe();
        };
    }, [updateSession]);

    const login = useCallback(async (email, password) => {
        try {
            const { data, error } = await supabase.auth.signInWithPassword({ 
                email, 
                password,
                options: {
                    persistSession: true,
                    cookieOptions: {
                        secure: true,
                        httpOnly: true,
                        sameSite: 'strict'
                    }
                }
            });
            if (error) throw error;
            updateSession(data.session);
        } catch (error) {
            setError(error.message);
            throw error;
        }
    }, [updateSession]);

    const logout = useCallback(async () => {
        try {
            await supabase.auth.signOut();
            updateSession(null);
        } catch (error) {
            setError(error.message);
            throw error;
        }
    }, [updateSession]);

    const refreshToken = useCallback(async () => {
        try {
            const { data, error } = await supabase.auth.refreshSession();
            if (error) throw error;
            updateSession(data.session);
            return data.session.access_token;
        } catch (error) {
            console.error('Error refreshing token:', error);
            setError(error.message);
            return null;
        }
    }, [updateSession]);

    const loginWithDiscord = async () => {
        try {
            const redirectUrl = process.env.NODE_ENV === 'production'
                ? 'https://trynotifyr.com/alerts'
                : window.location.origin + '/alerts';
                
            const { error } = await supabase.auth.signInWithOAuth({
                provider: 'discord',
                options: {
                    scopes: 'identify email',
                    redirectTo: redirectUrl
                },
            });

            if (error) throw error;
        } catch (error) {
            console.error('Discord login error:', error);
            throw error;
        }
    };

    const registerDiscordUser = async (session) => {
        if (!session?.user?.id) {
            throw new Error('Invalid session');
        }

        // Check if user is already registered using localStorage
        const registeredUsers = JSON.parse(localStorage.getItem('registeredUsers') || '{}');
        if (registeredUsers[session.user.id]) {
            console.log('User already registered, skipping registration');
            return { success: true, cached: true };
        }

        try {
            const discordData = session.user.user_metadata;
            
            if (!discordData?.provider_id) {
                throw new Error('Invalid Discord data');
            }

            const response = await fetch('/api/users/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                },
                body: JSON.stringify({
                    uuid: session.user.id,
                    email: session.user.email,
                    auth_type: 'discord',
                    discord_data: {
                        id: discordData.provider_id,
                        username: discordData.full_name || discordData.user_name,
                    },
                }),
            });

            const data = await response.json();

            // If registration was successful or user already exists
            if (response.status === 200 || response.status === 409) {
                // Store the registration status in localStorage
                registeredUsers[session.user.id] = {
                    timestamp: Date.now(),
                    email: session.user.email
                };
                localStorage.setItem('registeredUsers', JSON.stringify(registeredUsers));
                return { success: true, data };
            }

            throw new Error(data.message || 'Failed to register user in backend');
        } catch (error) {
            console.error('Error registering Discord user:', error);
            throw error;
        }
    };

    const value = useMemo(() => ({
        user,
        session,
        loading,
        error,
        login,
        logout,
        refreshToken,
        loginWithDiscord,
        registerDiscordUser,
        getAccessToken: () => session?.access_token,
    }), [user, session, loading, error, login, logout, refreshToken]);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};