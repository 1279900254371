import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ExternalLink } from 'lucide-react';

const UserDealsSection = () => {
  const userDeals = [
    { productName: "HS510C Holosun Optic", retailPrice: 360, boughtPrice: 210, subreddit: "GunAccessoriesForSale", link: "https://www.reddit.com/r/GunAccessoriesForSale/comments/1fhk7rh/wts_psa_mlok_16_in_upper_holosun_hs510c_holo/" },
    { productName: "Seiko SKX007 Diver's Watch", retailPrice: 350, boughtPrice: 180, subreddit: "WatchExchange", link: "#" },
    { productName: "AMD Ryzen 5 5600X", retailPrice: 299, boughtPrice: 199, subreddit: "buildapcsales", link: "#" },
    { productName: "J.Crew Ludlow Suit Jacket", retailPrice: 425, boughtPrice: 170, subreddit: "frugalmalefashion", link: "#" },
    { productName: "Elden Ring (PC)", retailPrice: 59.99, boughtPrice: 39.99, subreddit: "GameDeals", link: "#" },
    { productName: "NVIDIA RTX 3080", retailPrice: 699, boughtPrice: 550, subreddit: "hardwareswap", link: "#" },
  ];

  const [alertDots, setAlertDots] = useState([]);

  useEffect(() => {
    setAlertDots(Array.from({ length: 10 }, (_, i) => i * 0.4));
  }, []);

  return (
    <div className="relative py-16 sm:py-20 overflow-hidden bg-gradient-to-b from-blue-100 to-blue-50">
      {/* Background with topo map and overlay */}
      <div className="absolute inset-0">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `url('/img/topo.jpg')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            opacity: 0.1
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-br from-blue-100 via-blue-50 to-white opacity-30" />
      </div>

      {/* Alert Dots */}
      {alertDots.map((delay, index) => (
        <AlertDot key={index} delay={delay} />
      ))}

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-8 sm:mb-12">
          <h2 className="text-3xl sm:text-4xl font-extrabold text-blue-900 sm:text-5xl">
            Incredible Community Savings
          </h2>
          <p className="mt-3 max-w-2xl mx-auto text-lg sm:text-xl text-blue-700 sm:mt-4">
            Examples of amazing deals discovered by our savvy community. See how much you could potentially save!
          </p>
        </div>
        <motion.div 
          className="bg-white bg-opacity-30 backdrop-filter backdrop-blur-sm rounded-xl shadow-lg p-4 sm:p-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
            {userDeals.map((deal, index) => (
              <DealCard key={index} {...deal} />
            ))}
          </div>
        </motion.div>
        <div className="mt-8 sm:mt-12 text-center">
          <motion.a 
            href="/contact?deal=true"
            className="inline-flex items-center px-4 sm:px-6 py-2 sm:py-3 border border-transparent text-sm sm:text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-300 ease-in-out"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Share Your Deal Discovery
            <ExternalLink className="ml-2 -mr-1 h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
          </motion.a>
        </div>
      </div>
    </div>
  );
};

const DealCard = ({ productName, retailPrice, boughtPrice, subreddit, link }) => {
  const savedAmount = retailPrice - boughtPrice;
  const percentSaved = ((savedAmount / retailPrice) * 100).toFixed(0);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="bg-white rounded-lg p-4 border border-blue-200 hover:border-indigo-300 hover:shadow-md transition-all duration-300"
    >
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-base lg:text-sm font-semibold text-gray-900 truncate pr-2 max-w-[70%] sm:max-w-[60%]">
          {productName}
        </h3>
        <span className="text-xs font-medium text-green-600 bg-green-100 px-2 py-1 rounded-full">
          {percentSaved}% off
        </span>
      </div>
      <p className="text-xs text-gray-600 mb-2">Found in r/{subreddit}</p>
      <div className="flex items-center justify-between">
        <div className="flex items-baseline space-x-2">
          <p className="text-xs text-gray-500 line-through">Retail ${retailPrice}</p>
          <p className="text-base font-bold text-green-600">Deal ${boughtPrice}</p>
        </div>
        <div className="flex items-center space-x-2">
          <p className="text-xs font-medium text-indigo-600">
            Save ${savedAmount}
          </p>
          <a 
            href={link} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-indigo-500 hover:text-indigo-600"
          >
            <ExternalLink className="w-4 h-4" />
          </a>
        </div>
      </div>
    </motion.div>
  );
};

const AlertDot = ({ delay }) => {
  return (
    <motion.div
      className="absolute w-2 h-2 bg-red-500 rounded-full"
      style={{
        left: `${Math.random() * 100}%`,
        top: `${Math.random() * 100}%`,
      }}
      initial={{ scale: 0, opacity: 0 }}
      animate={{
        scale: [0, 1.5, 0],
        opacity: [0, 1, 0],
      }}
      transition={{
        duration: 4,
        delay,
        repeat: Infinity,
        repeatDelay: Math.random() * 10,
      }}
    />
  );
};

export default UserDealsSection;