import React from 'react';
import { Edit } from 'lucide-react';
import { motion } from 'framer-motion';

const ProfileCard = ({ user }) => {
  return (
    <motion.div 
      className="bg-white shadow hover:shadow-md rounded-lg overflow-hidden border border-gray-200 p-6 h-full flex flex-col justify-between"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.1 }}
    >
      <div>
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">Profile Information</h2>
        <div className="space-y-3">
          <div className="flex justify-between">
            <span className="font-medium">Email:</span>
            <span>{user.email}</span>
          </div>
          {/* Add more profile information here if needed */}
        </div>
      </div>
      <button 
        className="mt-4 flex items-center text-blue-600 hover:text-blue-800 text-sm font-medium"
      >
        <Edit className="w-4 h-4 mr-1" />
        Edit Profile
      </button>
    </motion.div>
  );
};

export default ProfileCard;
