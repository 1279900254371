import React from 'react';
import { motion, useAnimationControls } from 'framer-motion';
import { ArrowRight, Bell, Zap } from 'lucide-react';

const CTASection = () => {
  return (
    <div className="relative py-32 bg-gradient-to-b from-amber-100 to-amber-50 overflow-hidden">
      {/* Background with topo map and overlay */}
      <div className="absolute inset-0">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `url('/img/topo.jpg')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            opacity: 0.1
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-br from-amber-100 via-amber-50 to-white opacity-30" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <motion.h2 
            className="text-4xl sm:text-5xl font-extrabold text-amber-900 mb-8"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Elevate Your Reddit Experience
          </motion.h2>
          <motion.p 
            className="mt-4 max-w-2xl mx-auto text-xl text-amber-700 mb-12"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            Join thousands of users who never miss a beat on Reddit. Start monitoring keywords and stay ahead of the conversation.
          </motion.p>
          <motion.div 
            className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <a
              href="/register"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 transition duration-300 ease-in-out transform hover:scale-105"
            >
              Get Started for Free
              <ArrowRight className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
            </a>
            <a
              href="/pricing"
              className="inline-flex items-center px-6 py-3 border border-amber-600 text-base font-medium rounded-md shadow-sm text-amber-600 bg-white hover:bg-amber-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 transition duration-300 ease-in-out transform hover:scale-105"
            >
              View Pricing
            </a>
          </motion.div>
        </div>

        <motion.div 
          className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <FeatureCard
            icon={<Bell className="h-6 w-6 text-amber-500" />}
            title="Instant Notifications"
            description="Get alerted the moment your keywords appear in any monitored subreddit."
          />
          <FeatureCard
            icon={<Zap className="h-6 w-6 text-amber-500" />}
            title="Lightning-Fast Processing"
            description="Our advanced algorithms ensure you're always first to know about relevant posts."
          />
          <FeatureCard
            icon={<ArrowRight className="h-6 w-6 text-amber-500" />}
            title="Easy Setup"
            description="Start monitoring your favorite subreddits in just a few clicks."
          />
        </motion.div>
      </div>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  const controls = useAnimationControls();
  const [isHovering, setIsHovering] = React.useState(false);

  React.useEffect(() => {
    if (isHovering) {
      controls.start({ rotate: 360, transition: { duration: 0.8 } });
    } else {
      controls.start({ rotate: 0, transition: { duration: 0 } });
    }
  }, [isHovering, controls]);

  return (
    <div className="flex items-start">
      <div className="flex-shrink-0">
        <div 
          className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-30 backdrop-blur-sm border border-amber-500 text-amber-500 cursor-pointer"
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <motion.div
            animate={controls}
          >
            {icon}
          </motion.div>
        </div>
      </div>
      <div className="ml-4">
        <h3 className="text-lg font-medium text-amber-900">{title}</h3>
        <p className="mt-2 text-base text-amber-700">{description}</p>
      </div>
    </div>
  );
};

export default CTASection;