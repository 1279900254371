import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footers';

const PrivacyPolicyPage = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Notifyr</title>
        <meta name="description" content="Read Notifyr's privacy policy to understand how we collect, use, and protect your personal information while providing Reddit keyword alert services." />
        <meta name="keywords" content="privacy policy, Notifyr, data protection, Reddit alerts, user privacy" />
      </Helmet>
      <div className="min-h-screen bg-gray-100">
        <Navbar />
        <div className="h-24"></div>
        <div className="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-extrabold text-gray-900 mb-8">Privacy Policy</h1>
          
          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">1. Information We Collect</h2>
            <p className="text-gray-700">We collect information you provide directly to us when you create an account, subscribe to our Notifyr service, or contact us for support. This includes:</p>
            <ul className="list-disc list-inside ml-4 mt-2 text-gray-700">
              <li>Your name</li>
              <li>Email address</li>
              <li>Discord username and ID (if you choose to receive Discord notifications)</li>
              <li>Keywords and subreddits you choose to monitor</li>
            </ul>
            <p className="text-gray-700 mt-2">We do not collect or store your Reddit username or payment information. All payment processing is handled securely by our third-party payment processor.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">2. How We Use Your Information</h2>
            <p className="text-gray-700">We use the information we collect to:</p>
            <ul className="list-disc list-inside ml-4 mt-2 text-gray-700">
              <li>Provide, maintain, and improve our Notifyr service</li>
              <li>Send you alerts and notifications based on your preferences</li>
              <li>Communicate with you about your account and our services</li>
              <li>Respond to your inquiries and support requests</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">3. Information Sharing and Disclosure</h2>
            <p className="text-gray-700">We do not share your personal information with any third parties. For payment processing, you interact directly with our payment processor, who handles all payment information. The payment processor is contractually obligated to use your information solely for processing your payments and managing your subscription.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">4. Data Retention</h2>
            <p className="text-gray-700">We retain your information for as long as your account is active or as needed to provide you services. If you close your account, we will delete your personal information within 30 days, except where we are required to retain it for legal or regulatory reasons.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">5. Security</h2>
            <p className="text-gray-700">We implement appropriate technical and organizational measures to protect your personal information against unauthorized or unlawful processing, accidental loss, destruction, or damage.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">6. Your Rights</h2>
            <p className="text-gray-700">You have the right to:</p>
            <ul className="list-disc list-inside ml-4 mt-2 text-gray-700">
              <li>Access and receive a copy of your personal data</li>
              <li>Rectify inaccurate personal data</li>
              <li>Request the deletion of your personal data</li>
              <li>Object to the processing of your personal data</li>
              <li>Request the restriction of processing of your personal data</li>
            </ul>
            <p className="text-gray-700 mt-2">To exercise these rights, please contact us using the information provided at the end of this policy.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">7. Changes to Our Privacy Policy</h2>
            <p className="text-gray-700">We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page and updating the "Last updated" date. We encourage you to review this privacy policy periodically for any changes.</p>
          </section>

          <p className="text-gray-700 mt-8">
            If you have any questions about this Privacy Policy or our data practices, please contact us at team@trynotifyr.com.
          </p>

          <p className="text-gray-700 mt-4">
            Last updated: {new Date().toLocaleDateString()}
          </p>

          <div className="mt-8">
            <Link to="/terms-of-service" className="text-blue-600 hover:text-blue-800 mr-4">View our Terms of Service</Link>
            <Link to="/refund-policy" className="text-blue-600 hover:text-blue-800">View our Refund Policy</Link>
          </div>
        </div>

        <Footer/>
      </div>
    </>
  );
};

export default PrivacyPolicyPage;