import React from 'react';
import { motion } from 'framer-motion';
import { Zap, Clock, Filter, Bell, ShoppingCart, X, AlertTriangle } from 'lucide-react';

const FeatureComparisonSection = () => {
  return (
    <div className="relative py-24 lg:py-32 bg-amber-50 overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <motion.h2 
          className="text-4xl sm:text-5xl font-extrabold text-amber-900 text-center mb-8"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          Be <span className="text-emerald-600">First</span>, Get the Deal
        </motion.h2>
        <motion.p 
          className="text-xl text-amber-800 text-center mb-16 max-w-3xl mx-auto"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          In the fast-paced world of Reddit deals, seconds matter. Our app ensures you're <span className="text-emerald-600 font-semibold">always first in line</span>, never missing out on the hottest offers.
        </motion.p>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <FeatureCard2
              title="With Our Lightning-Fast Alerts"
              features={[
                { icon: <Zap className="w-6 h-6" />, text: <>Be the <span className="text-emerald-600 font-semibold">first</span> to comment and claim deals</>, highlight: true },
                { icon: <Clock className="w-6 h-6" />, text: <>Receive notifications <span className="text-emerald-600 font-semibold">within seconds</span> of posting</> },
                { icon: <Filter className="w-6 h-6" />, text: <><span className="text-emerald-600 font-semibold">Customize</span> alerts for specific items you're after</> },
                { icon: <Bell className="w-6 h-6" />, text: <>Get <span className="text-emerald-600 font-semibold">instant</span> notifications on your preferred device</> },
                { icon: <ShoppingCart className="w-6 h-6" />, text: <><span className="text-emerald-600 font-semibold">Score amazing deals</span> before they're gone</> },
              ]}
              isGood={true}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <FeatureCard2
              title="Without Our App"
              features={[
                { icon: <X className="w-6 h-6" />, text: <><span className="text-red-600 font-semibold">Miss out</span> as others claim deals before you</> },
                { icon: <AlertTriangle className="w-6 h-6" />, text: <><span className="text-red-600 font-semibold">Waste time</span> constantly refreshing Reddit</> },
                { icon: <X className="w-6 h-6" />, text: <><span className="text-red-600 font-semibold">Lose opportunities</span> due to delayed notifications</> },
                { icon: <X className="w-6 h-6" />, text: <><span className="text-red-600 font-semibold">Struggle</span> to track multiple subreddits for deals</> },
                { icon: <Clock className="w-6 h-6" />, text: <>Arrive <span className="text-red-600 font-semibold">too late</span>, finding only 'already sold' comments</> },
              ]}
              isGood={false}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

const FeatureCard2 = ({ title, features, isGood }) => {
  const cardStyle = isGood
    ? "bg-amber-100 border-t-4 border-amber-500 shadow-lg"
    : "bg-amber-50 border border-amber-200 shadow-md";
  const iconStyle = isGood ? "text-amber-600" : "text-amber-400";
  const textStyle = isGood ? "text-amber-900" : "text-amber-700";

  return (
    <motion.div 
      className={`rounded-lg overflow-hidden ${cardStyle}`}
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className={`px-6 py-8 ${isGood ? 'bg-amber-200' : 'bg-amber-100'}`}>
        <h3 className={`text-2xl font-semibold ${isGood ? 'text-amber-900' : 'text-amber-800'}`}>{title}</h3>
      </div>
      <ul className="px-6 py-8 space-y-4">
        {features.map((feature, index) => (
          <motion.li 
            key={index} 
            className="flex items-start"
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3 }}
          >
            <span className={`flex-shrink-0 mr-3 ${iconStyle}`}>{feature.icon}</span>
            <span className={`text-lg ${textStyle}`}>{feature.text}</span>
          </motion.li>
        ))}
      </ul>
    </motion.div>
  );
};

export default FeatureComparisonSection;