import React, { useState } from 'react';
import { Zap, Search, Layers, Sliders } from 'lucide-react';
import './FeatureSection.css';

const FeatureSection = () => {
  const [activeFeature, setActiveFeature] = useState(0);

  const features = [
    {
      icon: <Zap className="h-6 w-6 lg:h-8 lg:w-8" />,
      title: "Lightning-Fast Processing",
      description: "Experience unparalleled speed with our sub-10ms processing time. Be the first to know about new posts and never miss an opportunity.",
      color: "text-blue-600",
      bgColor: "from-blue-400/20 to-blue-600/20",
      highlight: "Sub-10ms processing",
      whyItMatters: [
        "React instantly to new opportunities",
        "Stay ahead of other users in fast-moving subreddits",
        "Maximize your chances of being first to respond"
      ]
    },
    {
      icon: <Search className="h-6 w-6 lg:h-8 lg:w-8" />,
      title: "Advanced Keyword Matching",
      description: "Utilize powerful AND/OR operators in your keyword searches. Fine-tune your alerts to catch exactly what you're looking for.",
      color: "text-purple-600",
      bgColor: "from-purple-400/20 to-purple-600/20",
      highlight: "AND/OR operators",
      whyItMatters: [
        "Refine your searches for more accurate results",
        "Reduce noise and irrelevant notifications",
        "Customize your alerts to match your specific needs"
      ]
    },
    {
      icon: <Layers className="h-6 w-6 lg:h-8 lg:w-8" />,
      title: "Smart Keyword Batching",
      description: "Our intelligent system groups related keywords together, optimizing your searches and reducing noise in your notifications.",
      color: "text-green-600",
      bgColor: "from-green-400/20 to-green-600/20",
      highlight: "Intelligent grouping",
      whyItMatters: [
        "Efficiently manage multiple related keywords",
        "Streamline your notification process",
        "Improve overall search performance"
      ]
    },
    {
      icon: <Sliders className="h-6 w-6 lg:h-8 lg:w-8" />,
      title: "Seamless User Interface",
      description: "Enjoy a smooth, intuitive experience with our carefully crafted UI. Set up alerts, manage keywords, and view results with ease.",
      color: "text-pink-600",
      bgColor: "from-pink-400/20 to-pink-600/20",
      highlight: "Intuitive design",
      whyItMatters: [
        "Save time with easy-to-use controls",
        "Quickly adapt and modify your monitoring setup",
        "Focus on results, not on learning complex interfaces"
      ]
    },
  ];

  return (
    <div className={`relative transition-all duration-700 ease-in-out ${
      activeFeature === 0 ? 'bg-gradient-to-br from-blue-50 via-blue-50 to-slate-100' :
      activeFeature === 1 ? 'bg-gradient-to-br from-purple-50 via-purple-50 to-slate-100' :
      activeFeature === 2 ? 'bg-gradient-to-br from-green-50 via-green-50 to-slate-100' :
      activeFeature === 3 ? 'bg-gradient-to-br from-red-50 via-red-50 to-slate-100' :
      'bg-gradient-to-br from-blue-50 via-blue-50 to-slate-100'
    }`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 lg:py-32 relative z-10">
        <div className="text-center mb-8 lg:mb-16">
          <h2 className="text-3xl lg:text-5xl font-extrabold text-slate-700 mb-4">
            Powerful Features for Reddit Monitoring
          </h2>
          <p className="text-lg lg:text-xl text-slate-600 max-w-3xl mx-auto">
            Our advanced features help you stay ahead of the competition and never miss an opportunity.
          </p>
        </div>

        <div className="relative">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {/* Feature Display Card */}
            <div className="order-first lg:order-last">
              <div className="mx-auto max-w-full lg:max-w-none">
                <div className="lg:h-[350px]">
                  <FeatureDisplay 
                    key={activeFeature} 
                    feature={features[activeFeature]} 
                  />
                </div>
              </div>
            </div>

            {/* Feature Buttons */}
            <div className="order-last lg:order-first flex flex-col">
              {features.map((feature, index) => (
                <FeatureButton
                  key={index}
                  feature={feature}
                  isActive={activeFeature === index}
                  onClick={() => setActiveFeature(index)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FeatureButton = ({ feature, isActive, onClick }) => {
  return (
    <button
      className={`w-full text-left rounded-lg shadow-sm overflow-hidden mb-3 last:mb-0 flex-grow
        transition-all duration-300 ease-in-out
        ${isActive
          ? 'bg-white backdrop-blur-md bg-opacity-90 border-2 border-blue-500'
          : 'bg-white bg-opacity-60 hover:bg-opacity-75 hover:border-blue-300 border-2 border-transparent'
        }`}
      onClick={onClick}
    >
      <div className="px-4 py-4">
        <div className="flex items-center">
          <div className={`${feature.color} flex-shrink-0 w-7 h-7 flex items-center justify-center`}>
            {feature.icon}
          </div>
          <span className={`font-semibold ${feature.color} ml-3 text-base lg:text-lg`}>
            {feature.title}
          </span>
        </div>
      </div>
    </button>
  );
};

const FeatureDisplay = ({ feature }) => {
  return (
    <div 
      className="w-full h-full p-6 rounded-lg bg-white shadow-lg flex flex-col relative overflow-hidden transition-all duration-300 ease-in-out feature-display"
    >
      <div className="overflow-y-auto flex-grow animate-feature-enter">
        <h3 className={`text-2xl font-bold ${feature.color} mb-2`}>{feature.title}</h3>
        <div className="inline-block mb-4">
          <div className={`px-3 py-1 rounded-md bg-gradient-to-r ${feature.bgColor} text-sm font-semibold ${feature.color}`}>
            {feature.highlight}
          </div>
        </div>
        <p className="text-gray-800 mb-6">{feature.description}</p>
      
        <div>
          <h4 className="text-lg font-semibold mb-2 text-gray-800">Why it matters</h4>
          <ul className="space-y-2">
            {feature.whyItMatters.map((item, index) => (
              <li key={index} className="flex items-start animate-why-it-matters" style={{ animationDelay: `${index * 150}ms` }}>
                <svg className="h-5 w-5 text-green-500 mr-2 mt-1 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                <span className="text-sm text-gray-700">{item}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FeatureSection;
