import React from 'react';
import { motion } from 'framer-motion';

export const FormBackground = () => (
  <div className="absolute inset-0 z-0">
    <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-indigo-50 opacity-75" />
  </div>
);

export const TestimonialBackground = () => (
  <motion.div
    className="absolute inset-0 z-0"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
  >
    <div className="absolute inset-0 bg-gradient-to-br from-blue-500 to-indigo-600 opacity-90" />
  </motion.div>
);

const DotPattern = () => (
  <svg className="absolute inset-0 h-full w-full" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <pattern id="dots" width="16" height="16" patternUnits="userSpaceOnUse">
        <circle cx="2" cy="2" r="1" fill="currentColor" fillOpacity="0.1" />
      </pattern>
    </defs>
    <rect width="100%" height="100%" fill="url(#dots)" />
  </svg>
);

export const FormBackgroundWithPattern = () => (
  <div className="absolute inset-0 z-0 overflow-hidden">
    <FormBackground />
  </div>
);

export const TestimonialBackgroundWithPattern = () => (
  <div className="absolute inset-0 z-0 overflow-hidden">
    <TestimonialBackground />
    <DotPattern />
  </div>
);

// You might need to add these background patterns to your CSS or as images
