import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { User, Lock } from 'lucide-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import supabase from '../../../supabase/supabaseClient';
import { useAuth } from '../../../AuthContext';
import useAuthenticatedApi from '../../../hooks/useAuthenticatedApi';
import { FormBackgroundWithPattern, TestimonialBackgroundWithPattern } from '../../../components/AuthBackgrounds';
import Navbar from '../../../components/Navbar';
import FeatureShowcase from '../../../components/FeatureShowcase';

const RegisterPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [registerError, setRegisterError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDiscordLoading, setIsDiscordLoading] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordFeedback, setPasswordFeedback] = useState('');
  const { setUser, loginWithDiscord, registerDiscordUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { authFetch, executeRequest } = useAuthenticatedApi();

  useEffect(() => {
    checkPasswordStrength(password);
  }, [password]);

  useEffect(() => {
    const checkAndRegisterDiscordUser = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session?.user?.app_metadata?.provider === 'discord') {
        try {
          await registerDiscordUser(session);
          const params = new URLSearchParams(location.search);
          const redirectUrl = params.get('redirect') || '/alerts';
          navigate(decodeURIComponent(redirectUrl));
        } catch (error) {
          console.error('Error registering Discord user:', error);
          setRegisterError('Failed to complete Discord registration');
        }
      }
    };

    checkAndRegisterDiscordUser();
  }, [registerDiscordUser, location.search, navigate]);

  const checkPasswordStrength = (password) => {
    let strength = 0;
    let feedback = [];

    if (password.length >= 8) {
      strength += 1;
    } else {
      feedback.push("Password should be at least 8 characters long");
    }

    if (password.match(/[a-z]/) && password.match(/[A-Z]/)) {
      strength += 1;
    } else {
      feedback.push("Include both uppercase and lowercase letters");
    }

    if (password.match(/\d/)) {
      strength += 1;
    } else {
      feedback.push("Include at least one number");
    }

    if (password.match(/[^a-zA-Z\d]/)) {
      strength += 1;
    } else {
      feedback.push("Include at least one special character");
    }

    setPasswordStrength(strength);
    setPasswordFeedback(feedback.join('. '));
  };

  const getPasswordStrengthColor = () => {
    switch (passwordStrength) {
      case 0: return 'bg-red-500';
      case 1: return 'bg-orange-500';
      case 2: return 'bg-yellow-500';
      case 3: return 'bg-blue-500';
      case 4: return 'bg-green-500';
      default: return 'bg-gray-300';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setRegisterError("Passwords do not match");
      return;
    }

    setIsLoading(true);
    try {
      const { data: authData, error: authError } = await supabase.auth.signUp({
        email,
        password,
      });

      if (authError) throw authError;

      if (authData.user) {
        const response = await executeRequest(async () => {
          return await authFetch('/api/auth/register', {
            method: 'POST',
            body: JSON.stringify({
              email: authData.user.email,
              supabase_uid: authData.user.id,
            }),
          });
        });

        if (response.user) {
          setUser(response.user);
          const params = new URLSearchParams(location.search);
          const redirectUrl = params.get('redirect') || '/';
          navigate(decodeURIComponent(redirectUrl));
        } else {
          throw new Error('Failed to create user in backend');
        }
      } else {
        throw new Error('Supabase registration successful but no user returned');
      }
    } catch (error) {
      console.error('Registration error:', error);
      setRegisterError('An error occurred during registration. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDiscordLogin = async () => {
    setIsDiscordLoading(true);
    try {
      await loginWithDiscord();
    } catch (error) {
      console.error('Discord login error:', error);
      setRegisterError('Failed to login with Discord. Please try again.');
      setIsDiscordLoading(false);
    }
  };

  const renderForm = () => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-white rounded-lg shadow-xl p-8 w-full max-w-md relative z-10"
    >
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Create Account</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-700">Email</label>
          <div className="relative">
            <User className="absolute left-3 top-3 text-gray-400" size={18} />
            <input
              type="email"
              className="w-full pl-10 pr-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white bg-opacity-80"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </div>
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-700">Password</label>
          <div className="relative">
            <Lock className="absolute left-3 top-3 text-gray-400" size={18} />
            <input
              type="password"
              className="w-full pl-10 pr-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white bg-opacity-80"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              required
            />
            <div className="absolute right-2 top-2 bottom-2 w-1 rounded-full overflow-hidden">
              <div 
                className={`h-full ${getPasswordStrengthColor()}`} 
                style={{ transform: `scaleY(${passwordStrength / 4})` }}
              ></div>
            </div>
          </div>
          {passwordFeedback && (
            <p className="mt-1 text-xs text-gray-600">
              {passwordStrength === 4 ? "Strong password" : passwordFeedback}
            </p>
          )}
        </div>
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-700">Confirm Password</label>
          <div className="relative">
            <Lock 
              className={`absolute left-3 top-3 ${
                confirmPassword 
                  ? password === confirmPassword 
                    ? 'text-green-500' 
                    : 'text-red-500'
                  : 'text-gray-400'
              }`} 
              size={18} 
            />
            <input
              type="password"
              className={`w-full pl-10 pr-3 py-2 rounded-lg border ${
                confirmPassword 
                  ? password === confirmPassword 
                    ? 'border-green-500 focus:ring-green-500' 
                    : 'border-red-500 focus:ring-red-500'
                  : 'border-gray-300 focus:ring-blue-500'
              } focus:outline-none focus:ring-2 bg-white bg-opacity-80`}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm your password"
              required
            />
          </div>
          {confirmPassword && password !== confirmPassword && (
            <p className="mt-1 text-xs text-red-500">Passwords do not match</p>
          )}
        </div>
        <button
          type="submit"
          className="w-full bg-blue-600 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300 flex items-center justify-center"
          disabled={isLoading}
        >
          {isLoading ? (
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          ) : null}
          {isLoading ? 'Registering...' : 'Register'}
        </button>
      </form>
      {registerError && <p className="text-red-500 text-center mt-4">{registerError}</p>}
      <div className="mt-4">
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">
              Or continue with
            </span>
          </div>
        </div>

        <button
          type="button"
          onClick={handleDiscordLogin}
          disabled={isDiscordLoading}
          className="mt-4 w-full flex justify-center items-center gap-2 bg-[#5865F2] text-white px-4 py-2 rounded-lg hover:bg-[#4752C4] transition-colors disabled:opacity-70 disabled:cursor-not-allowed"
        >
          {isDiscordLoading ? (
            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          ) : (
            <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z" />
            </svg>
          )}
          {isDiscordLoading ? 'Connecting...' : 'Sign up with Discord'}
        </button>
      </div>

      <p className="mt-4 text-center text-gray-600">
        Already have an account?
        <Link to={`/login${location.search}`} className="ml-2 text-blue-600 hover:underline">
          Login
        </Link>
      </p>
    </motion.div>
  );

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={location.pathname}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <Helmet>
          <title>Register - Reddit Alert</title>
          <meta name="description" content="Create your Reddit Alert account to start monitoring Reddit posts and comments with custom keyword alerts." />
          <meta name="keywords" content="register, sign up, Reddit Alert, create account" />
        </Helmet>
        <div className="min-h-screen flex flex-col">
          <Navbar />
          <div className="flex-grow flex">
            <div className="hidden lg:flex w-1/2 items-center justify-center p-12 relative overflow-hidden">
              <TestimonialBackgroundWithPattern />
              <div className="relative z-10">
                <FeatureShowcase />
              </div>
            </div>
            <div className="w-full lg:w-1/2 flex items-center justify-center p-4 lg:p-12 relative overflow-hidden">
              <FormBackgroundWithPattern />
              <div className="relative z-10 w-full max-w-md pt-16"> 
                {renderForm()}
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default RegisterPage;
