import React from 'react';
import LoadingSpinner from '../../components/LoadingSpinner';

const KeywordListStats = ({ subreddits, isLoading, error }) => {
  if (isLoading) {
    return (
      <div className="bg-white/50 backdrop-blur-sm rounded-lg shadow-md p-6 mt-8 border border-slate-100">
        <div className="flex items-center justify-center py-8">
          <LoadingSpinner size="large" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white/50 backdrop-blur-sm rounded-lg shadow-md p-6 mt-8 border border-slate-100">
        <div className="text-center text-red-600 py-8">
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white/50 backdrop-blur-sm rounded-lg shadow-md p-6 mt-8 border border-slate-100">
      <h2 className="text-2xl font-semibold mb-6 text-gray-800">Subreddit Keywords Statistics</h2>
      <div className="space-y-6">
        {subreddits.map((subreddit) => (
          <div 
            key={subreddit.name} 
            className="bg-white rounded-lg p-4 shadow-sm border border-blue-200/80 hover:border-blue-300/90 hover:shadow-md transition-all duration-200"
          >
            <div className="flex items-center mb-4 gap-3">
              <div className="min-w-0 flex-1">
                <a 
                  href={`https://reddit.com/r/${subreddit.name}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-lg font-medium text-blue-600 hover:text-blue-700 transition-colors truncate block"
                  title={`r/${subreddit.name}`}
                >
                  r/{subreddit.name}
                </a>
              </div>
              <div className="flex-shrink-0">
                <span className="px-3 py-1 rounded-full text-xs font-medium bg-blue-100 text-blue-800 border border-blue-200 whitespace-nowrap">
                  {subreddit.keywords.length} {subreddit.keywords.length === 1 ? 'keyword' : 'keywords'}
                </span>
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200 bg-white rounded-lg">
                <thead className="bg-slate-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Keyword</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Matches</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Matches/Hour</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Avg. Processing</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Match</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {subreddit.keywords.map((item, index) => (
                    <tr key={index} className="hover:bg-blue-50/50 transition-colors duration-150">
                      <td className="px-6 py-4">
                        <div className="text-sm font-medium text-gray-900">{item.keyword}</div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm font-semibold text-gray-900">{item.totalMatches.toLocaleString()}</div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm text-gray-900">{item.matchesPerHour.toFixed(1)}</div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm text-gray-900">{item.avgProcessingTime}s</div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm text-gray-500">{item.lastMatch}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KeywordListStats; 