import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/Navbar';
import Footer from '../components/Footers';
import { motion } from 'framer-motion';

const NotFoundPage = () => {
  return (
    <>
      <Helmet>
        <title>404 - Page Not Found | Notifyr</title>
        <meta name="description" content="The page you're looking for doesn't exist." />
      </Helmet>
      <div className="min-h-screen flex flex-col bg-gradient-to-br from-blue-50 via-indigo-100 to-purple-100">
        <Navbar />
        <div className="flex-grow flex items-center justify-center px-4 sm:px-6 lg:px-8 pt-32 pb-32">
          <div className="max-w-3xl w-full text-center">
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <h1 className="text-9xl font-extrabold text-blue-600 mb-4">404</h1>
              <p className="text-3xl font-semibold text-blue-800 mb-8">Oops! Page not found</p>
              <p className="text-xl text-blue-700 mb-12">The page you're looking for doesn't exist or has been moved.</p>
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Link 
                  to="/" 
                  className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-300 ease-in-out transform"
                >
                  Go back to homepage
                </Link>
              </motion.div>
            </motion.div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default NotFoundPage;
