import React from 'react';
import LoadingSpinner from '../../components/LoadingSpinner';

const InfoCard = ({ icon, title, value, isLoading }) => (
  <div className="bg-white rounded-lg shadow-sm p-6 flex items-center border-2 border-blue-100 hover:border-blue-300 hover:shadow-md transition-all duration-300">
    <div className="bg-gradient-to-br from-blue-50 to-blue-100 rounded-full p-3 mr-4">
      {React.cloneElement(icon, { className: "text-blue-500 w-6 h-6" })}
    </div>
    <div className="flex-grow">
      <h3 className="text-sm font-medium text-gray-500 mb-1">{title}</h3>
      {isLoading ? (
        <LoadingSpinner size="small" />
      ) : (
        <p className="text-2xl font-bold text-gray-800">{value}</p>
      )}
    </div>
  </div>
);

export default InfoCard;
